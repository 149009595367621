import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { AppActions, SET_GEOTARGETS } from "../types/actions";
import Geotarget from "../types/Geotarget";
import { baseUrl } from "../config/const";

export const setGeotargets = (geotargets: Geotarget[]): AppActions => ({
  type: SET_GEOTARGETS,
  geotargets
});

export const addGeotarget = (
  newGeotarget: Geotarget
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${baseUrl}/geotargets/`, newGeotarget)
        .then(res => {
          const toplist = res.data;
          const geotargets = [...getState().geotargets, toplist];

          dispatch(setGeotargets(geotargets));
          resolve();
        })
        .catch(error => {
          reject(error.response);
        });
    });
  };
};

export const setGeotarget = (
  updatedGeotarget: Geotarget
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>(resolve => {
      axios
        .put(`${baseUrl}/geotargets/${updatedGeotarget.id}/`, updatedGeotarget)
        .then(res => {
          const toplist = res.data;
          const geotargets = getState().geotargets.map((c: Geotarget) => {
            return c.id === toplist.id ? toplist : c;
          });

          dispatch(setGeotargets(geotargets));
          resolve();
        });
    });
  };
};

export const deleteGeotarget = (
  deletedGeotarget: Geotarget
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>(resolve => {
      axios
        .delete(`${baseUrl}/geotargets/${deletedGeotarget.id}/`)
        .then(res => {
          const toplist = res.data;
          const geotargets = getState().geotargets.filter((c: Geotarget) => {
            return c.id !== deletedGeotarget.id;
          });

          dispatch(setGeotargets(geotargets));
          resolve();
        });
    });
  };
};

export const getGeotargets = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  any
> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>(resolve => {
      axios.get(`${baseUrl}/geotargets/`).then(res => {
        dispatch(setGeotargets(res.data));
        resolve();
      });
    });
  };
};
