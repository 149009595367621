import React, { Component } from "react";
import { connect } from "react-redux";
import RedirectTypes from "../types/Redirect";
import ClicksTypes from "../types/Clicks";
import CasinoTypes from "../types/Casino";
import { AppState } from "../store/configureStore";
import { bindActionCreators } from "redux";
import { setRedirect, addRedirect, removeRedirect } from "../actions/redirects";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../types/actions";
import Modal from "react-responsive-modal";
import Datetime from "react-datetime";
import moment from "moment";
import ActionButton from "./subcomponents/ActionButton";

type Props = LinkStateProps & LinkDispatchProps;
class Redirect extends Component<Props> {
  state = {
    modalTitle: "",
    modalIsOpen: false,
    modalErrors: [],
    date: new Date()
  };

  redirectForm = React.createRef() as any;
  selectedRedirect = {} as RedirectTypes;

  onEntered() {
  }

  onCloseModal() {
    this.setState({
      modalIsOpen: false,
      modalErrors: []
    });
  }

  onAddRedirect() {
    this.selectedRedirect = {
      name: "",
      goLink: ""
    } as RedirectTypes;
    this.setState({
      modalTitle: "Add",
      modalIsOpen: true,
      modalErrors: []
    });
  }

  onEditRedirect(redirect: RedirectTypes) {
    this.selectedRedirect = JSON.parse(JSON.stringify(redirect));
    this.setState({
      modalTitle: "Edit",
      modalIsOpen: true,
      modalErrors: []
    });
  }

  onRemoveRedirect(redirect: RedirectTypes) {
    this.selectedRedirect = JSON.parse(JSON.stringify(redirect));
    this.setState({
      modalTitle: "Delete",
      modalIsOpen: true,
      modalErrors: []
    });
  }

  onSaveRedirect() {
    const self = this;
    const redirect = {
      name: getInputValue("name"),
      goLink: getInputValue("goLink")
    } as RedirectTypes;

    if (["Delete", "Edit"].includes(this.state.modalTitle))
      redirect.id = this.selectedRedirect.id;
    let errors: string[] = [];
    this.props[
      this.state.modalTitle === "Add"
        ? "addRedirect"
        : this.state.modalTitle === "Delete"
        ? "removeRedirect"
        : "setRedirect"
    ](redirect)
      .then(() => {
        this.setState({ modalIsOpen: false });
      })
      .catch((error: any) => {
        const {messages} = error.data.error.details
        const messagesArr = Object.keys(messages).map(key => `${key}: ${messages[key].join(', ')}`)
        this.setState({modalErrors: messagesArr})
      })

    function getInputValue(name: string) {
      return self.redirectForm.current.querySelector(`[name="${name}"]`).value;
    }
  }

  getRedirectItems() {
    return this.props.redirects;
  }

  getClickCountTemplate() {
    const { date } = this.state;
    return this.getRedirectItems().map((redirect, index) => (
      <li key={index} className="card--light my-1 p-3 d-flex">
        <p className={`my-0 mr-3 ${!redirect.name && "hidden"}`}>
          {redirect.name} <small>({redirect.goLink}):</small>
        </p>
        <p className="my-0">
          {
            this.props.clicks
              .filter(click => click.redirectId === redirect.id)
              .filter(click =>
                date ? moment(date).isSame(click.clickedAt, "month") : true
              ).length
          }
        </p>
      </li>
    ));
  }

  handleDate(date: any) {
    this.setState({ date });
  }

  getClickCounts() {
    const { date } = this.state;
    return this.props.clicks
      .filter(click =>
        this.props.redirects
          .map(redirect => redirect.id)
          .includes(click.redirectId)
      )
      .filter(click =>
        date ? moment(date).isSame(click.clickedAt, "month") : true
      ).length;
  }

  clearDate() {
    this.setState({ date: undefined });
  }

  render() {
    return (
      <div>
        <div className="card p-3 mb-5">
          <div className={`product-block my-2`}>
            <h3 className="">Redirects</h3>
            <div className="actions-block">
              <small className="text-uppercase">Actions</small>
              <div className="actions-items my-1">
                <ActionButton 
                  actionType="add" 
                  actionName="redirect" 
                  clickHandler={this.onAddRedirect.bind(this)} /> 
              </div>
            </div>
            <div className="redirect-block my-2">
              <small className="text-uppercase">List</small>
              <p className={`${this.getRedirectItems().length && "hidden"}`}>
                No items to display.
              </p>
              <ul className="redirect-list list-unstyled my-2">
                {this.getRedirectItems().map((redirect, index) => (
                  <li
                    key={index}
                    className="card--light my-1 p-3 rounded d-flex justify-content-between"
                    style={{ fontSize: "14px" }}
                  >
                    <div className="details d-flex align-items-center">
                      {
                        <p
                          className={`my-0 mr-3 ${!redirect.name && "hidden"}`}
                        >
                          {redirect.name}:
                        </p>
                      }
                      {redirect.goLink}
                    </div>
                    <div className="actions-block">
                      <button
                        className="btn text--light text-uppercase py-0"
                        onClick={this.onEditRedirect.bind(this, redirect)}
                      >
                        <small>Edit</small>
                      </button>
                      <button
                        className="btn text--light danger text-uppercase py-0"
                        onClick={this.onRemoveRedirect.bind(this, redirect)}
                      >
                        <small>Delete</small>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <Datetime
                value={this.state.date}
                dateFormat="MMMM YYYY"
                timeFormat={false}
                onChange={this.handleDate.bind(this)}
                inputProps={{ placeholder: "Select Date", disabled: true }}
                closeOnSelect={true}
                className="redirect-datepicker my-2"
              />
              <div className="d-flex my-2">
                <ActionButton 
                  actionType="view" 
                  actionName="all" 
                  clickHandler={this.clearDate.bind(this)}
                  buttonClass="btn btn-card" /> 
              </div>
            </div>
            <div className="clicks-block my-4 d-flex flex-column">
              <small className="text-uppercase">Clicks Details</small>
              <p className={`${this.getRedirectItems().length && "hidden"}`}>
                No items to display.
              </p>
              <div className={`${!this.getRedirectItems().length && "hidden"}`}>
                <small>
                  <span className="mr-1">Total number of clicks: </span>{" "}
                  {this.getClickCounts.call(this)}
                </small>
                <ul className="clicks-list list-unstyled my-2"></ul>
                {this.getClickCountTemplate()}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.modalIsOpen}
          onEntered={this.onEntered.bind(this)}
          onClose={this.onCloseModal.bind(this)}
          classNames={{
            overlay: "modal-overlay",
            modal: "modal-body"
          }}
        >
          <h3 className="mb-3">{this.state.modalTitle} Redirect</h3>
          <ul className="modal-errors list-unstyled">
            {this.state.modalErrors.map(error => {
              return <li className="text-error text-uppercase">{error}</li>;
            })}
          </ul>
          {
            <div
              ref={this.redirectForm}
              className={this.state.modalTitle === "Delete" ? "hidden" : ""}
            >
              Name{" "}
              <input
                type="text"
                name="name"
                defaultValue={this.selectedRedirect.name}
                className="form-control"
              />
              Go Link{" "}
              <input
                type="text"
                name="goLink"
                defaultValue={this.selectedRedirect.goLink}
                className="form-control"
              />
            </div>
          }
          {
            <div className={this.state.modalTitle === "Delete" ? "" : "hidden"}>
              <p>
                Are you sure you want to{" "}
                <span className="text-danger">delete</span> this item?
              </p>
            </div>
          }
          <div className="mt-5 text-right">
            <button
              className="btn btn-info mr-2"
              onClick={this.onCloseModal.bind(this)}
            >
              Cancel
            </button>
            <button
              className={
                this.state.modalTitle === "Delete"
                  ? "btn btn-danger"
                  : "btn btn-primary"
              }
              onClick={this.onSaveRedirect.bind(this)}
            >
              {this.state.modalTitle === "Delete" ? "Delete" : "Save"}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

interface LinkStateProps {
  casinos: CasinoTypes[];
  redirects: RedirectTypes[];
  clicks: ClicksTypes[];
}

interface LinkDispatchProps {
  addRedirect: (newRedirect: RedirectTypes) => any;
  setRedirect: (updatedRedirect: RedirectTypes) => any;
  removeRedirect: (removedRedirect: RedirectTypes) => any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  casinos: state.casinos,
  redirects: state.redirects,
  clicks: state.clicks
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AppActions>
): LinkDispatchProps => ({
  addRedirect: bindActionCreators(addRedirect, dispatch),
  setRedirect: bindActionCreators(setRedirect, dispatch),
  removeRedirect: bindActionCreators(removeRedirect, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
