import React, { Component } from "react";
import { connect } from "react-redux";
import Casino from "../types/Casino";
import { AppState } from "../store/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../types/actions";
import { Tabs, Tab } from "react-bootstrap";
import Language from "../types/Language";
import Modal from "react-responsive-modal";
import EditableList from "../utils/EditableList";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { addLanguage, setLanguage } from "../actions/languages";
import { addGeotarget, setGeotarget, deleteGeotarget } from "../actions/geotargets";
import Setting from "../types/Setting";
import { addSetting, setSetting } from "../actions/settings";
import { addSite, setSite } from "../actions/sites";
import { selectStyles } from "../config/const";
import Site from "../types/Site";
import GeotargetTypes from "../types/Geotarget";
import Toplist from "../types/Toplist";
import SelectTypes from "../types/Select";
import { startCase, isEqual } from "lodash";
import ActionButton from "./subcomponents/ActionButton";
import ItemFilter from "./subcomponents/ItemFilter";
import RequestURL from "./subcomponents/RequestURL";
import CasinoItemLanguage from "./subcomponents/CasinoItemLanguage";

type Props = PassedProps & LinkStateProps & LinkDispatchProps;
type tag = string;

class Geotarget extends Component<Props> {
  state = {
    uncheckedTags: [] as tag[],
    orderBy: "desc",
    activeLanguage: "",
    activeCountry: "Default",
    modalTitleType: "",
    modalTitle: "",
    modalExtraTitle: "",
    modalIsOpen: false,
    modalSelectedCasino: {} as SelectTypes,
    modalSelectedCountry: {} as SelectTypes,
    modalSelectedLanguage: {} as SelectTypes,
    modalSelectedToplist: {} as SelectTypes,
    modalSelectedSites: undefined as any,
    modalErrors: [],
    displayedSite: {} as SelectTypes,
    countryCodes: []
  };
  languageForm = React.createRef() as any;
  selectedLanguage = {} as Language;
  selectedGeotarget = {} as GeotargetTypes;
  selectedToplist = {} as Toplist;
  selectedSite = {} as Site;

  componentWillReceiveProps(nextProps: any, prevState: any) {
    const { sites } = nextProps;
    const { displayedSite } = this.state;
    let selectSiteOptions;
    if (sites.length && !displayedSite.hasOwnProperty("value")) {
      selectSiteOptions = sites.map(
        (site: { name: string; id: any; }) => ({
          label: site.name,
          value: site.id
        })
      );
      
      this.selectedSite = sites[0];
      this.setState({ displayedSite: selectSiteOptions[0] });
    }

    this.setState({ countryCodes: ['Default'].concat(this.getCountries(displayedSite.value)) });
}

  componentWillMount() {
    const { sites } = this.props;
    const { displayedSite } = this.state;
    let selectSiteOptions;
    if (sites.length && !displayedSite.hasOwnProperty("value")) {
      selectSiteOptions = sites.map(sites => ({
        label: sites.name,
        value: sites.id
      }));

      this.selectedSite = sites[0];
      this.setState({ displayedSite: selectSiteOptions[0], countryCodes: ['Default'].concat(this.getCountries()) });
    }
    
    this.setState({ countryCodes: ['Default'].concat(this.getCountries(displayedSite.value)) });
  }

  getCountries(activeSite: number = 1): string[] {
    const geotargets = new Set();
    this.props.geotargets
      .filter(lang => lang.site == activeSite)
      .forEach(lang => {
        geotargets.add(lang.code);
      });
    return [...geotargets].sort() as string[];
  }

  getTags(): tag[] {
    const tags = new Set();
    if (Array.isArray(this.props.casinos))
      this.props.casinos.forEach(casino => {
        if (Array.isArray(casino.tags))
          casino.tags.forEach(tag => tags.add(tag));
      });
    return [...tags] as tag[];
  }

  getActiveGeo(country: string) {
    const { displayedSite } = this.state;
    const activeSite = displayedSite.value || null;
    const countryValue = country === "Default" ? null : country;
    return this.props.geotargets.find(
      geotarget =>
        geotarget.code === countryValue && geotarget.site === activeSite
    ) as GeotargetTypes;
  }

  getActiveToplist(geotarget: GeotargetTypes) {
    return (geotarget &&
      this.props.toplists.find(
        toplist => toplist.id === geotarget.toplist
      )) as Toplist;
  }

  filterCasinosByCountry(country: string) {
    const uncheckedTags = this.getTags().filter(tag =>
      this.state.uncheckedTags.includes(tag)
    );

    const geotarget = this.getActiveGeo(country);

    const casinos = this.props.languages
      .filter(lang => {
        const casino = this.props.casinos.find(
          casino => casino.id === lang.casino
        );
        const hasTag = !!uncheckedTags.length && casino ? casino.tags.some(tag => !uncheckedTags.includes(tag)) : true
        return (
          geotarget !== undefined &&
          lang.toplist === geotarget.toplist &&
          casino !== undefined &&
          hasTag
        );
      })
      .sort((languageA: Language, languageB: Language): number => {
        if (languageA === undefined || languageB === undefined) return 0;

        return this.state.orderBy === "asc"
          ? languageB.rank - languageA.rank
          : languageA.rank - languageB.rank;
      });

    return casinos;
  }

  setFilter(event: React.FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let newState = {} as any;
    newState[name] = value;
    this.setState(newState);
  }

  setTag(event: React.FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const uncheckedTags = this.state.uncheckedTags;

    if (uncheckedTags.includes(value))
      uncheckedTags.splice(uncheckedTags.indexOf(value), 1);
    else uncheckedTags.push(value);

    this.setState({ uncheckedTags });
  }

  getToplistsSelect() {
    return this.props.toplists.map(toplist => ({
      label: toplist.name,
      value: toplist.id
    }));
  }

  getDefaultCodeValue() {
    const { code } = this.selectedGeotarget;

    return { label: code, value: code } as SelectTypes;
  }
  getDefaultToplistValue() {
    const { name, id } = this.selectedToplist;
    return { label: name, value: id };
  }

  getAssignedToplistSelectDefault() {
    const currentToplist = this.selectedLanguage.toplist;
    const defaultValue = this.getToplists().find(
      toplist => toplist.value == currentToplist
    );

    return defaultValue;
  }

  getLanguageCodeSelectDefault() {
    const { languageCode } = this.selectedLanguage;
    const defaultValue = this.props.languageCodes.find(
      code => code.value == languageCode
    );

    return defaultValue;
  }

  getSitesSelect() {
    return this.props.sites.map(site => ({
      label: site.name,
      value: site.id
    }));
  }

  getAvailableToplistsSelect() {
    const selectedLanguage = this.selectedLanguage;
    const duplicateItems = this.props.languages
      .filter(
        language =>
          language.toplist === selectedLanguage.toplist &&
          language.casino === selectedLanguage.casino &&
          language.languageCode === selectedLanguage.languageCode
      )
      .map(language => language.toplist);

    return this.getToplists().filter(
      toplist =>
        ![...new Set(duplicateItems)].some(item => item == toplist.value)
    );
  }

  getToplistSelectDefault() {
    return this.getSitesSelect().filter(toplist => toplist.value === 0);
  }

  getToplists() {
    let toplists = this.getSitesSelect();
    return toplists;
  }

  onItemClick(language: Language) {
    this.selectedLanguage = JSON.parse(JSON.stringify(language));

    this.setState({
      modalTitleType: "Edit",
      modalTitle: "Casino",
      modalExtraTitle: "Translation",
      modalIsOpen: true,
      modalSelectedToplist: { value: this.selectedLanguage.toplist },
      modalSelectedLanguage: { value: this.selectedLanguage.languageCode },
      modalSelectedCasino: { value: this.selectedLanguage.casino }
    });
  }

  onSelectSite(modalSelectedSites: any) {
    let selectedSites = modalSelectedSites || [];
    const sites = selectedSites.map((x: any) => x.value);

    this.setState({ modalSelectedSites: sites.length ? sites : null });
  }

  onSelectAvailableSite(modalSelectedSites: any) {
    this.setState({ modalSelectedSites: modalSelectedSites.value });
  }

  onSelectDisplayedSite(
    displayedSite: any,
    newData: any = { code: "", languageCode: "" }
  ) {
    const modalSelectedSites = displayedSite.value;
    const countryCodes = [
      ...new Set(["Default"].concat(this.getCountries(displayedSite.value)))
    ];
    const activeLanguage = !!newData.languageCode
      ? newData.languageCode
      : this.state.activeLanguage;

    let activeCountry = this.getCountries(displayedSite.value).includes(
      this.state.activeCountry
    )
      ? this.state.activeCountry
      : "Default";
      this.selectedSite = this.props.sites.find(
        site => site.id === displayedSite.value
      ) as Site;

    activeCountry = !!newData.code ? newData.code : activeCountry;

    this.setState({
      displayedSite,
      modalSelectedSites,
      countryCodes,
      activeCountry,
      activeLanguage,
      modalIsOpen: false,
      modalErrors: []
    });
  }
  getLanguageDefault(languages: string[]) {
    const { activeLanguage } = this.state;

    languages = languages.sort();
    const newLanguage =
      !!activeLanguage && languages.includes(activeLanguage)
        ? activeLanguage
        : languages[0];

    return newLanguage;
  }

  onCloseModal() {
    this.setSelecttedLanguageDefault();
    this.setState({
      modalIsOpen: false,
      modalExtraTitle: "",
      modalSelectedCasino: {},
      modalSelectedCountry: { value: null },
      modalSelectedLanguage: {},
      modalSelectedSites: this.state.displayedSite.value,
      modalErrors: []
    });
  }

  setSelecttedLanguageDefault() {
    this.selectedLanguage = {
      languageCode: "",
      alternateName: "",
      bonusValue: 0,
      beforeBonusValue: "",
      afterBonusValue: "",
      bonusOffer: "",
      bonusOfferImage: "",
      signupBonus: "",
      keyFeatures: [],
      toplist: 0,
      casino: 0,
      goTo: "",
      rank: 0
    } as Language;
  }

  onAddGeotarget() {
    this.setSelecttedLanguageDefault();
    this.setState({
      modalTitleType: "Add",
      modalTitle: "Geotarget",
      modalIsOpen: true,
      modalSelectedSites: this.state.displayedSite.value,
      modalSelectedCasino: {},
      modalSelectedCountry: { value: null },
      modalSelectedLanguage: {},
      modalSelectedToplist: {}
    });
  }

  onEditSite() {
    this.selectedSite = this.props.sites.find(
      site => site.id === this.state.displayedSite.value
    ) as Site;
    this.setState({
      modalTitleType: "Edit",
      modalTitle: "Site",
      modalIsOpen: true,
      modalSelectedSites: this.state.displayedSite.value,
      modalSelectedCasino: {},
      modalSelectedCountry: { value: null },
      modalSelectedLanguage: {}
    });
  }

  onEditGeotarget(geotarget: GeotargetTypes) {
    this.setSelecttedLanguageDefault();
    this.selectedGeotarget = geotarget;
    this.selectedToplist = this.props.toplists.find(
      toplist => toplist.id === geotarget.toplist
    ) as Toplist;
    this.setState({
      modalTitleType: "Edit",
      modalTitle: "Toplist",
      modalIsOpen: true,
      modalSelectedSites: this.state.displayedSite.value,
      modalSelectedCasino: {},
      modalSelectedCountry: { value: this.selectedGeotarget.code },
      modalSelectedLanguage: {},
      modalSelectedToplist: { value: geotarget.toplist }
    });
  }

  onSelectToplist(modalSelectedToplist: any) {
    this.setState({ modalSelectedToplist });
  }

  onSelectCountry(modalSelectedCountry: any) {
    this.setState({ modalSelectedCountry });
  }

  onSelectLanguage(modalSelectedLanguage: any) {
    this.setState({ modalSelectedLanguage });
  }

  onSelectDefaultCountry(modalSelectedCountry: any) {
    const propName = "defaultCountry";
    const currentValue = this.props.settings.find(
      setting => setting.prop === propName
    );
    const setting = {
      id: currentValue ? currentValue.id : undefined,
      prop: propName,
      value: modalSelectedCountry.value
    };
    let service;

    if (!currentValue) {
      service = this.props.addSetting;
    } else {
      service = this.props.setSetting;
    }

    service(setting);
  }

  async saveGeotarget() {
    // const getInputValue = (name: string) => {
    //   return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    // };
    const sites = Array.isArray(this.state.modalSelectedSites)
      ? this.state.modalSelectedSites
      : [this.state.modalSelectedSites];
    const currentGeotargets = [...this.props.geotargets];
    let errors: string[] = [];
    const postGeotarget = (geotarget: GeotargetTypes) => {
      return this.props[
        this.state.modalTitleType === "Add" ? "addGeotarget" : "setGeotarget"
      ](geotarget)
        .then((response: any) => {
          this.onSelectDisplayedSite.call(
            this,
            this.state.displayedSite,
            geotarget
          );
        })
        .catch((response: any) => {
          const { data } = response;

          if (!Object.keys(data).length) return;

          errors =
            typeof data === "string"
              ? ["Error: Integrity Error"]
              : Object.keys(data).map(key => {
                  return `${startCase(key)}: ${data[key]}`;
                });
        });
    };

    const geotarget = {
      code: this.state.modalSelectedCountry.value,
      toplist: this.state.modalSelectedToplist.value
    } as GeotargetTypes;

    if (
      this.selectedGeotarget.hasOwnProperty("id") &&
      this.selectedGeotarget.id
    )
      geotarget.id = this.selectedGeotarget.id;

    const resultsPromise = sites.map(async (site: any) => {
      geotarget.site = site === undefined ? null : site;

      return postGeotarget(geotarget);
    });

    await Promise.all(resultsPromise).then(data => {
      const isEdit = this.state.modalTitleType === "Edit";
      const duplicateEntries = sites
        .filter(site => !isEdit)
        .filter((site: any) => {
          const isDuplicate = currentGeotargets
            .filter(geo => geo.site !== null && geo.site === site)
            .some(
              geo =>
                geo.code === geotarget.code && geotarget.toplist
            );

          return isDuplicate;
        });

      if (duplicateEntries.length) {
        errors.push(
          `Duplicate entry for Site(s): ${duplicateEntries
            .map(entry => {
              const site =
                this.props.sites.find(site => site.id === entry) || ({} as any);
              return site.hasOwnProperty("name") ? site.name : [];
            })
            .join(", ")}`
        );
      }

      this.setState({ modalErrors: errors });
    });
  }

  async saveCasino() {
    const getInputValue = (name: string) => {
      return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    };

    let errors: string[] = [];
    const postLanguage = (language: Language[]) => {
      return this.props["setLanguage"](language)
        .then((response: any) => {
          this.onSelectDisplayedSite.call(
            this,
            this.state.displayedSite,
            language
          );
        })
        .catch((response: any) => {
          const { data } = response;

          if (!Object.keys(data).length) return;

          errors =
            typeof data === "string"
              ? ["Error: Integrity Error"]
              : Object.keys(data).map(key => {
                  return `${startCase(key)}: ${data[key]}`;
                });
        });
    };

    let language = {
      toplist: this.state!.modalSelectedToplist!.value,
      casino: this.state!.modalSelectedCasino!.value,
      languageCode: this.state!.modalSelectedLanguage!.value,
      alternateName: getInputValue("name"),
      bonusValue: getInputValue("bonusValue"),
      beforeBonusValue: getInputValue("beforeBonusValue"),
      afterBonusValue: getInputValue("afterBonusValue"),
      bonusOffer: getInputValue("bonusOffer"),
      bonusOfferImage: getInputValue("bonusOfferImage"),
      signupBonus: getInputValue("signupBonus"),
      keyFeatures: this.selectedLanguage.keyFeatures || [],
      goTo: getInputValue("goTo"),
      rank: this.selectedLanguage.rank
    } as Language;

    if (this.selectedLanguage.id) language.id = this.selectedLanguage.id;

    postLanguage([language]).then((data: any) => {
      this.setState({ modalErrors: errors });
    });
  }

  async saveSite() {
    const getInputValue = (name: string) => {
      return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    };

    let errors: string[] = [];
    const postSite = (site: Site) => {
      return this.props[
        this.state.modalTitleType === "Add" ? "addSite" : "setSite"
      ](site)
        .then((response: any) => {
          this.onSelectDisplayedSite.call(this, this.state.displayedSite, site);
        })
        .catch((response: any) => {
          const { data } = response;

          if (!Object.keys(data).length) return;

          errors =
            typeof data === "string"
              ? ["Error: Integrity Error"]
              : Object.keys(data).map(key => {
                  return `${startCase(key)}: ${data[key]}`;
                });
        });
    };

    let site = {
      name: getInputValue("name"),
      url: getInputValue("url"),
      image: getInputValue("image")
    } as Site;

    if (this.selectedSite.id) site.id = this.selectedSite.id;

    postSite(site).then((data: any) => {
      this.setState({ modalErrors: errors });
    });
  }

  async onModalSave() {
    // const getInputValue = (name: string) => {
    //   return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    // };

    switch (this.state.modalTitle) {
      case "Geotarget":
      case "Toplist":
        this.saveGeotarget.call(this);
        break;
      case "Casino":
        this.saveCasino.call(this);
        break;
      case "Site":
        this.saveSite.call(this);
        break;
    }
  }

  getActiveCountryTabLanguages() {
    const { activeCountry } = this.state;
    const activeGeo = this.getActiveGeo(activeCountry);
    const activeToplist = this.getActiveToplist(activeGeo);

    return [
      ...new Set(
        this.props.languages.filter(language => {
          return activeToplist && language.toplist === activeToplist.id;
        }).map(language => language.languageCode))];
  }

  onRemoveGeo(geotarget: GeotargetTypes) {
    this.selectedGeotarget = JSON.parse(JSON.stringify(geotarget));
    this.props.deleteGeotarget(geotarget)
      .then((response: any) => {
        console.log('success')
      })
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    let updateApp = true;
    // if (state.activeLanguage !== nextState.activeLanguage) {
    //   updateApp = false;
    // }
    return updateApp;
  }

  render() {
    const { selectedSite } = this;
    const { countryCodes, displayedSite } = this.state;
    const { languageCodes } = this.props
    const currentCountryTabLanguages = this.getActiveCountryTabLanguages.call(
      this
    );

    const activeGeo = this.getActiveGeo(this.state.activeCountry);
    const activeToplist = this.getActiveToplist(activeGeo);
    if (!!countryCodes.length && !displayedSite.value && !languageCodes.length) {
      return (<div>Loading...</div>)
    } 
    return (
      <div className="d-flex flex-column">
        <div className="">
          Select Site{" "}
          <div className="d-flex align-items-center">
            <Select
              className="flex-grow-1 mr-1"
              value={{
                label: selectedSite.name,
                value: selectedSite.id
              }}
              noOptionsMessage={() => "No more options"}
              options={this.getToplists.call(this)}
              onChange={this.onSelectDisplayedSite.bind(this)}
              styles={selectStyles}
            ></Select>
            <div className={this.state.displayedSite.value && this.state.displayedSite.value !== 1 ? "" : "d-none"} >
              <ActionButton 
                actionType="edit" 
                actionName="site" 
                clickHandler={this.onEditSite.bind(this)} /> 
            </div>
          </div>
        </div>
        <div className="text-center my-3">
          <ActionButton 
            actionType="add" 
            actionName="geotarget" 
            clickHandler={this.onAddGeotarget.bind(this)} />
        </div>
        <div className={activeToplist ? "mb-2" : "d-none"}>
          <div className="d-inline-flex py-1 px-2 card--light small">
            Toplist: {activeToplist && activeToplist.name}
          </div>
          <ActionButton 
            actionType="change" 
            actionName="toplist" 
            clickHandler={this.onEditGeotarget.bind(this, activeGeo)} />
          <button 
            className="btn btn-danger"
            onClick={this.onRemoveGeo.bind(this, activeGeo)}>
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
        {countryCodes.length ? (
          <div className="d-flex">
            <div className="flex-grow-1">
              <Tabs
                id="toplist-tabs"
                activeKey={this.state.activeCountry}
                onSelect={(activeCountry: any) => {
                  this.setState({ activeCountry });
                }}
              >
                {countryCodes.map((country, index) =>
                  <Tab
                  key={`${country}-${this.state.displayedSite.value}-${index}`}
                  eventKey={country}
                  title={country}
                >
                  <Tabs
                    id={`languages-tabs-${this.state.displayedSite.value}-${index}`}
                    activeKey={this.getLanguageDefault(currentCountryTabLanguages)}
                    onSelect={(activeLanguage: any) => {
                      this.setState({ activeLanguage });
                    }}
                  >
                    {
                      currentCountryTabLanguages.length ? 
                        currentCountryTabLanguages.map((language, index) => {
                          const languageItems = this.filterCasinosByCountry(country).filter(
                            item => item.languageCode === language
                          );
                          const tabTitle = languageCodes.find(
                            code => code.value === language
                          ).label;
                          return (
                            <Tab
                              key={`${language}-${displayedSite.value}-${index}`}
                              eventKey={language}
                              title={tabTitle} >
                              {
                                languageItems.map(languageItem => {
                                  const casino = this.props.casinos.find(
                                    casino => casino.id === languageItem.casino
                                  );
                                  return casino !== undefined ? (
                                    <CasinoItemLanguage 
                                      casino={casino}
                                      language={languageItem}
                                      itemCount={languageItems.length}
                                      itemClickHandler={this.onItemClick.bind(this)}
                                    />
                                  ) : (
                                    ""
                                  );
                              })}
                            </Tab>
                          )
                      }) : "" 
                    }
                  </Tabs>
                </Tab>
                )}
              </Tabs>
            </div>
            <div className="ml-3" style={{ width: "200px" }}>
              <ItemFilter 
                tags={this.getTags()} 
                orderBy={this.state.orderBy} 
                filterHandler={this.setFilter.bind(this)} 
                tagHandler={this.setTag.bind(this)}/>
              <RequestURL 
                orderBy={this.state.orderBy} 
                languageCode={this.getLanguageDefault(currentCountryTabLanguages)}
                tags={this.getTags()}
                uncheckedTags={this.state.uncheckedTags} />
            </div>
          </div>
        ) : (
          "No items to display."
        )}
        <Modal
          open={this.state.modalIsOpen}
          onClose={this.onCloseModal.bind(this)}
          classNames={{
            overlay: "modal-overlay",
            modal: "modal-body"
          }}
        >
          <h3 className="mb-3">
            {this.state.modalTitleType} {this.state.modalTitle}{" "}
            {this.state.modalExtraTitle}
          </h3>
          {this.state.modalIsOpen ? (
            <div ref={this.languageForm}>
              <ul className="modal-errors list-unstyled">
                {this.state.modalErrors.map(error => {
                  return <li className="text-error text-uppercase">{error}</li>;
                })}
              </ul>
              {this.state.modalTitleType === "Add" ? (
                <div>
                  Sites to include{" "}
                  <Select
                    isMulti
                    defaultValue={[this.state.displayedSite]}
                    noOptionsMessage={() => "No more options"}
                    options={this.getSitesSelect.call(this)}
                    onChange={this.onSelectSite.bind(this)}
                    styles={selectStyles}
                  ></Select>
                  <div
                    className="d-flex flex-column"
                  >
                    <span>Country / Country Code</span>
                    <span className="my-1" style={{ fontSize: "11px" }}>
                      * Leaving this blank will add it to{" "}
                      <strong>default</strong>.
                    </span>

                    <Select
                      options={this.props.countries}
                      noOptionsMessage={() => "No more options"}
                      onChange={this.onSelectCountry.bind(this)}
                      styles={selectStyles}
                    ></Select>
                  </div>
                  Toplist{" "}
                  <Select
                    options={this.getToplistsSelect.call(this)}
                    noOptionsMessage={() => "No more options"}
                    onChange={this.onSelectToplist.bind(this)}
                    styles={selectStyles}
                  ></Select>
                </div>
              ) : this.state.modalTitle === "Geotarget" ||
                this.state.modalTitle === "Toplist" ? (
                <div>
                  <div
                    className="d-flex flex-column"
                  >
                    <span>Country / Country Code</span>
                    <span className="my-1" style={{ fontSize: "11px" }}>
                      * Leaving this blank will add it to{" "}
                      <strong>default</strong>.
                    </span>
                    <Select
                      defaultValue={this.getDefaultCodeValue()}
                      noOptionsMessage={() => "No more options"}
                      options={this.props.countries}
                      onChange={this.onSelectCountry.bind(this)}
                      styles={selectStyles}
                    ></Select>
                  </div>
                  Toplist
                  <Select
                    defaultValue={this.getDefaultToplistValue()}
                    noOptionsMessage={() => "No more options"}
                    options={this.getToplistsSelect.call(this)}
                    onChange={this.onSelectToplist.bind(this)}
                    styles={selectStyles}
                  ></Select>
                </div>
              ) : this.state.modalTitle === "Casino" ? (
                <div className="mb-2">
                  {/* TODO: Show country */}
                  {/* <div className="mb-2">
                    Country:{" "}
                    <code>{this.selectedLanguage.code || "Default"}</code>
                  </div> */}
                  <div className="my-2">
                    Casino:{" "}
                    <code>
                      {
                        this.props.casinos.find(
                          c => c.id === this.selectedLanguage.casino
                          )!.name
                        }
                    </code>
                  </div>
                  <div>
                    Go To{" "}
                    <input
                      type="text"
                      name="goTo"
                      defaultValue={this.selectedLanguage.goTo}
                      className="form-control"
                    />
                  </div>
                  <div>
                    Alternate Name{" "}
                    <input
                      type="text"
                      name="name"
                      defaultValue={this.selectedLanguage.alternateName}
                      className="form-control"
                    />
                    <div className="my-4">
                      Bonus Value{" "}
                      <input
                        type="number"
                        name="bonusValue"
                        defaultValue={
                          this.selectedLanguage.bonusValue
                          ? this.selectedLanguage.bonusValue.toString()
                          : ""
                        }
                        className="form-control"
                      />
                      <div className="ml-3">
                        <div className="d-flex align-items-center my-1"> 
                          <span className="small mr-2">Before</span> 
                          <input
                            type="text"
                            name="beforeBonusValue"
                            placeholder="Insert text before bonus value"
                            defaultValue={this.selectedLanguage.beforeBonusValue || ""}
                            className="form-control my-0 small"
                            />
                        </div>
                        <div className="d-flex align-items-center my-1"> 
                          <span className="small mr-3">After</span> 
                          <input
                            type="text"
                            name="afterBonusValue"
                            placeholder="Insert text after bonus value"
                            defaultValue={this.selectedLanguage.afterBonusValue || ""}
                            className="form-control my-0 small"
                            />
                        </div>
                      </div>
                    </div>
                    Bonus Offer{" "}
                    <input
                      type="text"
                      name="bonusOffer"
                      defaultValue={this.selectedLanguage.bonusOffer || ""}
                      className="form-control"
                    />
                    Bonus Offer Image{" "}
                    <input
                      type="text"
                      name="bonusOfferImage"
                      defaultValue={this.selectedLanguage.bonusOfferImage || ""}
                      className="form-control"
                    />
                    Signup Bonus{" "}
                    <input
                      type="text"
                      name="signupBonus"
                      defaultValue={this.selectedLanguage.signupBonus}
                      className="form-control"
                    />
                    <EditableList
                      label={"Key Features"}
                      list={this.selectedLanguage.keyFeatures}
                    ></EditableList>
                  </div>
                </div>
              ) : (
                <div>
                  Name{" "}
                  <input
                    type="text"
                    name="name"
                    defaultValue={this.selectedSite.name}
                    className="form-control"
                  />
                  URL{" "}
                  <input
                    type="text"
                    name="url"
                    defaultValue={this.selectedSite.url}
                    className="form-control"
                  />
                  Image{" "}
                  <input
                    type="text"
                    name="image"
                    defaultValue={this.selectedSite.image}
                    className="form-control"
                  />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="mt-5 text-right">
            <button
              className="btn btn-info mr-2"
              onClick={this.onCloseModal.bind(this)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={this.onModalSave.bind(this)}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

interface PassedProps {
  countries: any[];
  languageCodes: any[];
}

interface LinkStateProps {
  casinos: Casino[];
  languages: Language[];
  settings: Setting[];
  sites: Site[];
  toplists: Toplist[];
  geotargets: GeotargetTypes[];
}

interface LinkDispatchProps {
  addLanguage: (newLanguage: Language) => any;
  setLanguage: (updatedLanguage: Language[]) => any;
  addGeotarget: (newGeotarget: GeotargetTypes) => any;
  setGeotarget: (updatedGeotarget: GeotargetTypes) => any;
  deleteGeotarget: (deletedGeotarget: GeotargetTypes) => any;
  addSetting: (newSetting: Setting) => any;
  setSetting: (updatedSetting: Setting) => any;
  addSite: (newSite: Site) => any;
  setSite: (updatedSite: Site) => any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  casinos: state.casinos,
  languages: state.languages,
  settings: state.settings,
  sites: state.sites,
  toplists: state.toplists,
  geotargets: state.geotargets
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AppActions>
): LinkDispatchProps => ({
  addLanguage: bindActionCreators(addLanguage, dispatch),
  setLanguage: bindActionCreators(setLanguage, dispatch),
  addGeotarget: bindActionCreators(addGeotarget, dispatch),
  setGeotarget: bindActionCreators(setGeotarget, dispatch),
  deleteGeotarget: bindActionCreators(deleteGeotarget, dispatch),
  addSetting: bindActionCreators(addSetting, dispatch),
  setSetting: bindActionCreators(setSetting, dispatch),
  addSite: bindActionCreators(addSite, dispatch),
  setSite: bindActionCreators(setSite, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Geotarget);
