import { ToplistActionTypes } from "../types/actions";
import Toplist from "../types/Toplist";

const defaultToplistsState: Toplist[] = [];

export function toplistsReducer(
  state = defaultToplistsState,
  action: ToplistActionTypes
) {
  switch (action.type) {
    case "SET_TOPLISTS":
      return action.toplists;
    default:
      return state;
  }
}
