import React, { Component } from "react";
import { connect } from "react-redux";
import Casino from "../types/Casino";
import { AppState } from "../store/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../types/actions";
import "../scss/Dashboard.scss";
import Language from "../types/Language";
import Modal from "react-responsive-modal";
import EditableList from "../utils/EditableList";
import { setCasino, addCasino } from "../actions/casinos";
import { bindActionCreators } from "redux";
import Payment from "../types/Payment";
import Select from "react-select";
import Site from "../types/Site";
import Toplist from "../types/Toplist";
import ActionButton from "./subcomponents/ActionButton";
import CasinoItem from "./subcomponents/CasinoItem";
import { selectStyles } from "../config/const";

type Props = LinkStateProps & LinkDispatchProps;

class Dashboard extends Component<Props> {
  state = {
    modalTitle: "",
    modalIsOpen: false,
    modalSelectedPayments: undefined as any
  };

  casinoForm = React.createRef() as any;
  selectedCasino = {} as Casino;

  getPaymentsSelectDefault() {
    const payments = this.props.payments;
    const casinoPayments = this.selectedCasino.payments;
    const wew = casinoPayments.map(payment => {
      return {
        label: payments.find(x => x.id === payment)!.name,
        value: payment
      };
    });
    return wew;
  }

  getPaymentsSelect() {
    return this.props.payments.map(payment => ({
      label: payment.name,
      value: payment.id
    }));
  }

  onCasinoClick(casino: Casino) {
    this.selectedCasino = JSON.parse(JSON.stringify(casino));
    this.setState({
      modalTitle: "Edit",
      modalIsOpen: true,
      modalSelectedPayments: this.selectedCasino.payments
    });
  }

  onSelectPayment(modalSelectedPayments: any) {
    let selectedPayments = modalSelectedPayments || [];
    const payments = selectedPayments.map((x: any) => x.value);
    this.setState({ modalSelectedPayments: payments });
  }

  onCloseModal() {
    this.setState({
      modalIsOpen: false,
      modalSelectedPayments: undefined
    });
  }

  onAddCasino() {
    this.selectedCasino = {
      name: "",
      image: "",
      goUrl: "",
      payments: [],
      rating: 0,
      tags: [],
      website: ""
    } as Casino;
    this.setState({
      modalTitle: "Add",
      modalIsOpen: true
    });
  }

  onSaveCasino() {
    const self = this;
    const casino = {
      id: this.selectedCasino.id || undefined,
      name: getInputValue("name"),
      image: getInputValue("image"),
      goUrl: getInputValue("goUrl"),
      rating: getInputValue("rating"),
      payments: this.state.modalSelectedPayments,
      website: getInputValue("website"),
      tags: this.selectedCasino.tags || []
    } as Casino;

    this.props[this.state.modalTitle === "Add" ? "addCasino" : "setCasino"](
      casino
    ).then(() => {
      this.setState({ modalIsOpen: false });
    });

    function getInputValue(name: string) {
      return self.casinoForm.current.querySelector(`[name="${name}"]`).value;
    }
  }

  render() {
    const { casinos } = this.props
    return (
      <div>
        <div className="text-center">
          <ActionButton 
            actionType="add" 
            actionName="casino" 
            clickHandler={this.onAddCasino.bind(this)} />
        </div>
        <div className="mt-3">
          {casinos.map((casino: Casino) => (
            <CasinoItem data={casino} clickHandler={this.onCasinoClick.bind(this, casino)}/>
          ))}
        </div>
        <Modal
          open={this.state.modalIsOpen}
          onClose={this.onCloseModal.bind(this)}
          classNames={{
            overlay: "modal-overlay",
            modal: "modal-body"
          }}
        >
          <h3 className="mb-3">{this.state.modalTitle} Casino</h3>
          {
            <div ref={this.casinoForm}>
              Name{" "}
              <input
                type="text"
                name="name"
                defaultValue={this.selectedCasino.name}
                className="form-control"
              />
              Image{" "}
              <input
                type="text"
                name="image"
                defaultValue={this.selectedCasino.image}
                className="form-control"
              />
              Go Link{" "}
              <input
                type="text"
                name="goUrl"
                defaultValue={this.selectedCasino.goUrl}
                className="form-control"
              />
              {/* TODO: Move to language (Unsure) */}
              Rating{" "}
              <input
                type="number"
                name="rating"
                min="0"
                max="5"
                defaultValue={
                  this.selectedCasino.rating
                    ? this.selectedCasino.rating.toString()
                    : ""
                }
                className="form-control"
              />
              Website{" "}
              <input
                type="text"
                name="website"
                defaultValue={this.selectedCasino.website}
                className="form-control"
              />
              {this.state.modalIsOpen ? (
                <div>
                  Payments{" "}
                  <Select
                    isMulti
                    defaultValue={this.getPaymentsSelectDefault.call(this)}
                    noOptionsMessage={() => "No more options"}
                    options={this.getPaymentsSelect.call(this)}
                    onChange={this.onSelectPayment.bind(this)}
                    styles={selectStyles}
                  ></Select>
                </div>
              ) : (
                ""
              )}
              <EditableList
                label={"Tags"}
                list={this.selectedCasino.tags}
              ></EditableList>
            </div>
          }
          <div className="mt-5 text-right">
            <button
              className="btn btn-info mr-2"
              onClick={this.onCloseModal.bind(this)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={this.onSaveCasino.bind(this)}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

interface LinkStateProps {
  casinos: Casino[];
  payments: Payment[];
  languages: Language[];
  sites: Site[];
  toplists: Toplist[];
}

interface LinkDispatchProps {
  addCasino: (newCasino: Casino) => any;
  setCasino: (updatedCasino: Casino) => any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  casinos: state.casinos,
  payments: state.payments,
  languages: state.languages,
  sites: state.sites,
  toplists: state.toplists
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AppActions>
): LinkDispatchProps => ({
  addCasino: bindActionCreators(addCasino, dispatch),
  setCasino: bindActionCreators(setCasino, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
