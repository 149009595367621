import { ClicksActionTypes } from '../types/actions';
import Clicks from '../types/Clicks';

const defaultClicksState: Clicks[] = [];

export function clicksReducer(state = defaultClicksState, action: ClicksActionTypes) {
  switch (action.type) {
    case 'SET_CLICKS':
      return action.clicks;
    default:
      return state;
  }
};