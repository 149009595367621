import { LanguageActionTypes } from '../types/actions';
import Language from '../types/Language';

const defaultLanguagesState: Language[] = [];

export function languagesReducer (state = defaultLanguagesState, action: LanguageActionTypes) {
  switch (action.type) {
    case 'SET_LANGUAGES':
      return action.languages;
    default:
      return state;
  }
};