const template = `<div className="card d-flex flex-row flex-wrap p-2 mb-3">
  <div className="w-100">
    <img src={casino.image} alt=""></img>
  </div>
  <div className="signup-bonus d-inline-flex flex-column justify-content-center align-items-center py-2" style="width: 15%;">
    <span>{casino.signupBonus}</span>
    <span className="font-weight-bold my-1" style="font-size: 200%;">
      {casino.bonusValue}{currency}
    </span>
    <a className="website" href={casino.website}>visit site</a>
  </div>
  <div className="features d-inline-flex flex-column justify-content-center align-items-center align-items-lg-start py-2 px-lg-2" style="width: 20%;">
    <div className="d-flex flex-column">
      {casino.languages[language].keyFeatures.map((kf) =>
        <span className="d-flex align-items-center">
          <i className="fas fa-check text-success mr-2"></i>
          <span>{kf}</span>
        </span>
      )}
    </div>
  </div>
  <div className="order-lg-1 d-flex justify-content-center align-items-center py-2" style="width: 25%;">
    <div className="go btn btn-warning font-weight-bold text-uppercase p-3" href={casino.goLink} target="_blank" style="font-size: 150%;">
    <i className="fas fa-caret-right"></i>
      Play now!
    </div>
  </div>
  <div className="rating d-flex flex-column justify-content-center align-items-center py-2" style="width: 15%;">
    <span style="font-size: 200%;">
      <div className="ui-star-rating d-block mb-1 text-warning">
        <i className="ion-md-star"></i>
      </div>
    </span>
    <span>
      {casino.rating} of 5 Stars
    </span>
    <a className="expert font-weight-bold text-center" href={\`/\${casino.name.replace(' ', '-').toLowerCase()}/\`}>{casino.name} <i className="fas fa-caret-right"></i></a>
  </div>
</div>`

export default template;