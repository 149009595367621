import axios from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store/configureStore';
import { AppActions, SET_CASINOS } from '../types/actions';
import Casino from '../types/Casino';
import { baseUrl } from '../config/const';

export const setCasinos = (casinos: Casino[]): AppActions => ({
  type: SET_CASINOS,
  casinos
})

export const addCasino = (newCasino:Casino):ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.post(`${baseUrl}/casinos/`, newCasino)
        .then((res) => {
          const casino = res.data
          const casinos = [...getState().casinos, casino]
      
          dispatch(setCasinos(casinos))
          resolve()
        })
    })
  }
}

export const setCasino = (updatedCasino:Casino):ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.put(`${baseUrl}/casinos/${updatedCasino.id}/`, updatedCasino)
        .then((res) => {
          const casino = res.data
          const casinos = getState().casinos.map((c: Casino) => {
            return c.id === casino.id ? casino : c;
          });
      
          dispatch(setCasinos(casinos))
          resolve()
        })
    })
  }
}

export const getCasinos = ():ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.get(`${baseUrl}/casinos/`)
        .then((res) => {
          dispatch(setCasinos(res.data));
          resolve()
        })
    })
  }
}