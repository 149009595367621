import React, {
	FunctionComponent
} from 'react';
import { baseUrl } from "../../config/const";

type Props = {
  orderBy: string,
  languageCode: string, 
  tags: string[],
  uncheckedTags: string[]
}

const RequestURL: FunctionComponent < Props > = ({ orderBy, languageCode, tags, uncheckedTags}) => {
		return (
			<div className="mt-3">
        <div>Request URL:</div>
        <code style={{ wordBreak: "break-all" }}>
          {baseUrl}/toplist?orderBy=
          {orderBy}
          {!!languageCode ? <span>&lang={languageCode}</span> : ""}
          {uncheckedTags.length > 0 ? (
            <span>
              &tags=
              {tags
                .filter(tag => !uncheckedTags.includes(tag))
                .map((tag, i, tags) => (
                  <span key={tag}>
                    {tag}
                    {i < tags.length - 1 ? "," : ""}
                  </span>
                ))}
            </span>
          ) : (
            ""
          )}
        </code>
      </div>
    )
	}

export default RequestURL;