import { SiteActionTypes } from "../types/actions";
import Site from "../types/Site";

const defaultSitesState: Site[] = [];

export function sitesReducer(
  state = defaultSitesState,
  action: SiteActionTypes
) {
  switch (action.type) {
    case "SET_SITES":
      return action.sites;
    default:
      return state;
  }
}
