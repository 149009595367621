import { GeotargetActionTypes } from "../types/actions";
import Geotarget from "../types/Geotarget";

const defaultGeotargetsState: Geotarget[] = [];

export function geotargetsReducer(
  state = defaultGeotargetsState,
  action: GeotargetActionTypes
) {
  switch (action.type) {
    case "SET_GEOTARGETS":
      return action.geotargets;
    default:
      return state;
  }
}
