import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { AppActions } from "../types/actions";
import { composeWithDevTools } from "redux-devtools-extension";
import { casinosReducer } from "../reducers/casinos";
import { languagesReducer } from "../reducers/languages";
import { redirectsReducer } from "../reducers/redirects";
import { clicksReducer } from "../reducers/clicks";
import { settingsReducer } from "../reducers/settings";
import { sitesReducer } from "../reducers/sites";
import { toplistsReducer } from "../reducers/toplists";
import { geotargetsReducer } from "../reducers/geotargets";
import { paymentsReducer } from "../reducers/payments";

export const rootReducer = combineReducers({
  casinos: casinosReducer,
  languages: languagesReducer,
  sites: sitesReducer,
  toplists: toplistsReducer,
  geotargets: geotargetsReducer,
  payments: paymentsReducer,
  redirects: redirectsReducer,
  clicks: clicksReducer,
  settings: settingsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
  )
);
