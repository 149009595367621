import { CasinoActionTypes } from '../types/actions';
import Casino from '../types/Casino';

const defaultCasinosState: Casino[] = [];

export function casinosReducer (state = defaultCasinosState, action: CasinoActionTypes) {
  switch (action.type) {
    case 'SET_CASINOS':
      return action.casinos;
    default:
      return state;
  }
};