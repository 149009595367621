import React, { Component } from 'react';
import MonacoEditor from 'react-monaco-editor';
import casinoTemplate from '../casinoTemplate.js';

class Template extends Component {
  state = {
    template: casinoTemplate,
    editorOptions: {
      minimap: {
        enabled: false
      }
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="d-flex mb-3">
            <span className="flex-grow-1">Sample Template</span>
            <code>&template=sampleTemplate</code>
          </h3>
          <MonacoEditor
            width="100%"
            height="800"
            language="html"
            theme="vs-dark"
            options={this.state.editorOptions}
            value={this.state.template}
          />
        </div>
      </div>
    );
  }
}

export default Template;