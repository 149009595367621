import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { AppActions, SET_TOPLISTS } from "../types/actions";
import Toplist from "../types/Toplist";
import { baseUrl } from "../config/const";
import { startCase } from "lodash";

export const setToplists = (toplists: Toplist[]): AppActions => ({
  type: SET_TOPLISTS,
  toplists
});

export const addToplist = (
  newToplist: Toplist
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${baseUrl}/toplists/`, newToplist)
        .then(res => {
          const toplist = res.data;
          const toplists = [...getState().toplists, toplist];

          dispatch(setToplists(toplists));
          resolve();
        })
        .catch(error => {
          reject(error.response);
        });
    });
  };
};

export const setToplist = (
  updatedToplist: Toplist
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`${baseUrl}/toplists/${updatedToplist.id}/`, updatedToplist)
        .then(res => {
          const toplist = res.data;
          const toplists = getState().toplists.map((c: Toplist) => {
            return c.id === toplist.id ? toplist : c;
          });

          dispatch(setToplists(toplists));
          resolve();
        })
        .catch(error => {
          let err
          const { data } = error.response;

          if (!Object.keys(data).length) return;

          err =
            typeof data === "string"
              ? ["Error: Integrity Error"]
              : Object.keys(data).map(key => {
                  let title = key
                  switch(key) {
                    case "defaultGoTo":
                      title = "defaultAffiliateLink"
                      break;
                  }

                  return `${startCase(title)}: ${data[key]}`;
                });

          reject(err);
        });;
    });
  };
};

export const getToplists = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  any
> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>(resolve => {
      axios.get(`${baseUrl}/toplists/`).then(res => {
        dispatch(setToplists(res.data));
        resolve();
      });
    });
  };
};
