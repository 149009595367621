import { RedirectActionTypes } from '../types/actions';
import Redirect from '../types/Redirect';

const defaultRedirectsState: Redirect[] = [];

export function redirectsReducer(state = defaultRedirectsState, action: RedirectActionTypes) {
  switch (action.type) {
    case 'SET_REDIRECTS':
      return action.redirects;
    default:
      return state;
  }
};