import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { AppActions, SET_LANGUAGES } from "../types/actions";
import Language from "../types/Language";
import { baseUrl } from "../config/const";

export const setLanguages = (languages: Language[]): AppActions => ({
  type: SET_LANGUAGES,
  languages
});

export const addLanguage = (
  newLanguage: Language
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const response = { data: {} } as any;

      getState()
        .languages.filter(
          lang =>
            lang.toplist === newLanguage.toplist && newLanguage.toplist !== null
        )

      if (Object.keys(response.data).length) {
        reject(response);
      } else {
        axios
          .post(`${baseUrl}/languages/`, newLanguage)
          .then(res => {
            const language = res.data;
            const languages = [...getState().languages, language];
            dispatch(setLanguages(languages));
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      }
    });
  };
};

export const setLanguage = (
  updatedLanguage: Language[]
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const response = { data: {} } as any;

      // getState()
      //   .languages.filter(
      //     lang =>
      //       lang.toplist === updatedLanguage.toplist &&
      //       updatedLanguage.toplist !== null
      //   )
        
      if (Object.keys(response.data).length) {
        reject(response);
      } else {
        axios
          .put(`${baseUrl}/languages/`, updatedLanguage)
          .then(res => {
            const currentLanguages = res.data;
            const languages = getState().languages.map((c: Language) => {
              const updateLanguage = currentLanguages.find((lang: Language) => lang.id === c.id)
              return updateLanguage || c 
            });

            dispatch(setLanguages(languages));
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      }
    });
  };
};

export const deleteLanguage = (
  deletedLanguage: Language
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const response = { data: {} } as any;
      
      axios
        .delete(`${baseUrl}/languages/${deletedLanguage.id}/`)
        .then(res => {
          const language = res.data;
          const languages = getState().languages.filter((c: Language) => {
            return c.id !== deletedLanguage.id;
          });
          dispatch(setLanguages(languages));
          resolve();
        })
        .catch(error => {
          reject(error.response);
        });
    });
  };
};

export const getLanguages = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  any
> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>(resolve => {
      axios.get(`${baseUrl}/languages/`).then(res => {
        dispatch(setLanguages(res.data));
        resolve();
      });
    });
  };
};
