import axios from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store/configureStore';
import { AppActions, SET_CLICKS } from '../types/actions';
import Clicks from '../types/Clicks';
import { remoteUrl } from '../config/const';

export const setClicks = (clicks: Clicks[]): AppActions => ({
  type: SET_CLICKS,
  clicks
});

export const getClicks = (): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.get(`${remoteUrl}/redirects/clicks`)
        .then((res) => {
          dispatch(setClicks(res.data));
          resolve();
        });
    })
  };
};