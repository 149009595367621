import axios from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store/configureStore';
import { AppActions, SET_PAYMENTS } from '../types/actions';
import Payment from '../types/Payment';
import { baseUrl } from '../config/const';

export const setPayments = (payments: Payment[]): AppActions => ({
  type: SET_PAYMENTS,
  payments
});

export const addPayment = (newPayment: Payment): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.post(`${baseUrl}/payments/`, newPayment)
        .then((res) => {
          const payment = res.data
          const payments = [...getState().payments, payment]

          dispatch(setPayments(payments))
          resolve()
        })
    })
  }
}

export const setPayment = (updatedPayment: Payment): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.put(`${baseUrl}/payments/${updatedPayment.id}/`, updatedPayment)
        .then((res) => {
          const payment = res.data
          const payments = getState().payments.map((c: Payment) => {
            return c.id === payment.id ? payment : c;
          });

          dispatch(setPayments(payments))
          resolve()
        })
    })
  }
}

export const getPayments = (): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.get(`${baseUrl}/payments/`)
        .then((res) => {
          dispatch(setPayments(res.data));
          resolve();
        });
    })
  };
};