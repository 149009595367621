import axios from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store/configureStore';
import { AppActions, SET_SETTINGS } from '../types/actions';
import Setting from '../types/Setting';
import { baseUrl } from '../config/const';

export const setSettings = (settings: Setting[]): AppActions => ({
  type: SET_SETTINGS,
  settings
});

export const addSetting = (newSetting:Setting):ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.post(`${baseUrl}/toplist/settings/`, newSetting)
        .then((res) => {
          const setting = res.data
          const settings = [...getState().settings, setting]
      
          dispatch(setSettings(settings))
          resolve()
        })
    })
  }
}

export const setSetting = (updatedSetting:Setting):ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.put(`${baseUrl}/toplist/settings/${updatedSetting.id}/`, updatedSetting)
        .then((res) => {
          const setting = res.data
          const settings = getState().settings.map((c: Setting) => {
            return c.id === setting.id ? setting : c;
          });
      
          dispatch(setSettings(settings))
          resolve()
        })
    })
  }
}

export const getSettings = ():ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.get(`${baseUrl}/toplist/settings/`)
        .then((res) => {
          dispatch(setSettings(res.data));
          resolve();
        });
    })
  };
};