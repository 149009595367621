import Casino from "./Casino";
import Language from "./Language";
import Site from "./Site";
import Toplist from "./Toplist";
import Geotarget from "./Geotarget";
import Payment from "./Payment";
import Setting from "./Setting";
import Redirect from "./Redirect";
import Clicks from "./Clicks";

export const SET_CASINOS = "SET_CASINOS";

export interface SetCasinos {
  type: typeof SET_CASINOS;
  casinos: Casino[];
}

export const SET_LANGUAGES = "SET_LANGUAGES";

export interface SetLanguages {
  type: typeof SET_LANGUAGES;
  languages: Language[];
}

export const SET_SITES = "SET_SITES";

export interface SetSites {
  type: typeof SET_SITES;
  sites: Site[];
}

export const SET_TOPLISTS = "SET_TOPLISTS";

export interface SetToplists {
  type: typeof SET_TOPLISTS;
  toplists: Toplist[];
}

export const SET_GEOTARGETS = "SET_GEOTARGETS";

export interface SetGeotargets {
  type: typeof SET_GEOTARGETS;
  geotargets: Geotarget[];
}

export const SET_PAYMENTS = "SET_PAYMENTS";

export interface SetPayments {
  type: typeof SET_PAYMENTS;
  payments: Payment[];
}

export const SET_REDIRECTS = "SET_REDIRECTS";

export interface SetRedirects {
  type: typeof SET_REDIRECTS;
  redirects: Redirect[];
}

export const SET_CLICKS = "SET_CLICKS";

export interface SetClicks {
  type: typeof SET_CLICKS;
  clicks: Clicks[];
}

export const SET_SETTINGS = "SET_SETTINGS";

export interface SetSettings {
  type: typeof SET_SETTINGS;
  settings: Setting[];
}

export type CasinoActionTypes = SetCasinos;
export type LanguageActionTypes = SetLanguages;
export type SiteActionTypes = SetSites;
export type ToplistActionTypes = SetToplists;
export type GeotargetActionTypes = SetGeotargets;
export type PaymentActionTypes = SetPayments;
export type RedirectActionTypes = SetRedirects;
export type ClicksActionTypes = SetClicks;
export type SettingsActionTypes = SetSettings;
export type AppActions =
  | CasinoActionTypes
  | LanguageActionTypes
  | SiteActionTypes
  | ToplistActionTypes
  | GeotargetActionTypes
  | PaymentActionTypes
  | RedirectActionTypes
  | ClicksActionTypes
  | SetSettings;
