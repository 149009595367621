import React, { Component } from "react";
import { BrowserRouter as Router, NavLink, Route, Switch } from "react-router-dom";
import "./App.scss";
import Dashboard from "./components/Dashboard";
import Geotarget from "./components/Geotarget";
import Toplists from "./components/Toplists";
import Template from "./components/Template";
import Redirect from "./components/Redirect";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "./types/actions";
import { bindActionCreators } from "redux";
import { getCasinos } from "./actions/casinos";
import { connect } from "react-redux";
import { getLanguages } from "./actions/languages";
import axios from "axios";
import { baseUrl } from "./config/const";
import { getSettings } from "./actions/settings";
import { getSites } from "./actions/sites";
import { getToplists } from "./actions/toplists";
import { getGeotargets } from "./actions/geotargets";
import { getPayments } from "./actions/payments";
import { getRedirects } from "./actions/redirects";
import { getClicks } from "./actions/clicks";

type Props = LinkStateProps & LinkDispatchProps;

class App extends Component<Props> {
  state = {
    countries: [],
    languageCodes: []
  };

  componentWillMount() {
    this.getCountries();
    this.getLanguageCodes();
    this.props.getCasinos();
    this.props.getLanguages();
    this.props.getSites();
    this.props.getToplists();
    this.props.getGeotargets();
    this.props.getPayments();
    this.props.getRedirects();
    this.props.getClicks();
    this.props.getSettings();
  }

  getCountries() {
    axios.get(`${baseUrl}/toplist/countries/`).then(res => {
      this.setState({ countries: res.data });
    });
  }

  getLanguageCodes() {
    axios.get(`${baseUrl}/toplist/languageCodes/`).then(res => {
      const languageCodes = this.toSelectFormat(res.data);
      this.setState({ languageCodes });
    });
  }

  toSelectFormat(data: any) {
    const codes = Object.keys(data).map(languageCode => {
      const details = data[languageCode];
      return {
        label: `${details.name} (${languageCode})`,
        value: languageCode
      };
    });
    return codes;
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          <header className="App-header">
            <nav className="fixed-top navbar navbar-dark bg-dark w-100">
              <ul className="navbar-nav flex-row justify-content-start container px-2">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    exact={true}
                    to="/"
                    activeClassName="active"
                  >
                    Casinos
                  </NavLink>
                </li>
                <li className="nav-item ml-3">
                  <NavLink
                    className="nav-link"
                    to="/geotarget/"
                    activeClassName="active"
                  >
                    Geotarget
                  </NavLink>
                </li>
                <li className="nav-item ml-3">
                  <NavLink
                    className="nav-link"
                    to="/toplists/"
                    activeClassName="active"
                  >
                    Toplists
                  </NavLink>
                </li>
                <li className="nav-item ml-3">
                  <NavLink
                    className="nav-link"
                    to="/template/"
                    activeClassName="active"
                  >
                    Templates
                  </NavLink>
                </li>
                <li className="nav-item ml-3">
                  <NavLink
                    className="nav-link"
                    to="/redirect/"
                    activeClassName="active"
                  >
                    Redirects
                  </NavLink>
                </li>
              </ul>
            </nav>
          </header>
          <section className="container">
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route
                path="/geotarget/"
                exact
                render={routeProps => (
                  <Geotarget
                  {...routeProps}
                  countries={this.state.countries}
                  languageCodes={this.state.languageCodes}
                  />
                  )}
                  />
              <Route
                path="/toplists/"
                exact
                render={routeProps => (
                  <Toplists
                  {...routeProps}
                  languageCodes={this.state.languageCodes}
                  />
                  )}
                  />
              <Route path="/template/" exact component={Template} />
              <Route path="/redirect/" exact component={Redirect} />
              <Route component={Dashboard} />
            </Switch>
          </section>
        </div>
      </Router>
    );
  }
}

interface LinkStateProps {}

interface LinkDispatchProps {
  getCasinos: () => void;
  getLanguages: () => void;
  getSites: () => void;
  getToplists: () => void;
  getGeotargets: () => void;
  getPayments: () => void;
  getRedirects: () => void;
  getClicks: () => void;
  getSettings: () => void;
}

const mapStateToProps = (): LinkStateProps => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AppActions>
): LinkDispatchProps => ({
  getCasinos: bindActionCreators(getCasinos, dispatch),
  getLanguages: bindActionCreators(getLanguages, dispatch),
  getSites: bindActionCreators(getSites, dispatch),
  getToplists: bindActionCreators(getToplists, dispatch),
  getGeotargets: bindActionCreators(getGeotargets, dispatch),
  getPayments: bindActionCreators(getPayments, dispatch),
  getRedirects: bindActionCreators(getRedirects, dispatch),
  getClicks: bindActionCreators(getClicks, dispatch),
  getSettings: bindActionCreators(getSettings, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
