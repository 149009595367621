import axios from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store/configureStore';
import { AppActions, SET_REDIRECTS } from '../types/actions';
import Redirect from '../types/Redirect';
import { remoteUrl } from '../config/const';
import { reject } from 'q';

const endpoint = '/redirects'

export const setRedirects = (redirects: Redirect[]): AppActions => ({
  type: SET_REDIRECTS,
  redirects
});

export const addRedirect = (newRedirect: Redirect): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      axios.post(`${remoteUrl}${endpoint}/`, newRedirect)
        .then((res) => {
          const redirect = res.data
          const redirects = [...getState().redirects, redirect]

          dispatch(setRedirects(redirects))
          resolve()
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
}

export const setRedirect = (updatedRedirect: Redirect): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      const { id } = updatedRedirect
      delete updatedRedirect.id
      axios.patch(`${remoteUrl}${endpoint}/${id}/`, updatedRedirect)
        .then((res) => {
          const redirect = JSON.parse(res.config.data)
          const redirects = getState().redirects.map((r: Redirect) => {
            redirect.id = id
            return r.id === id ? redirect : r
          });

          dispatch(setRedirects(redirects))
          resolve()
        })
    })
  }
}

export const removeRedirect = (removedRedirect: Redirect): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>, getState: () => AppState): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.delete(`${remoteUrl}${endpoint}/${removedRedirect.id}/`)
        .then((res) => {
          const redirects = getState().redirects.filter((r: Redirect) => {
            return r.id !== removedRedirect.id
          });

          dispatch(setRedirects(redirects))
          resolve()
        })
    })
  }
}

export const getRedirects = (): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>((resolve) => {
      axios.get(`${remoteUrl}${endpoint}/`)
        .then((res) => {
          dispatch(setRedirects(res.data));
          resolve();
        });
    })
  };
};
