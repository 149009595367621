import React, {
	FunctionComponent
} from 'react';
import { startCase } from "lodash";

type ButtonProps = {
	actionType: string,
	actionName: string,
	clickHandler: () => void,
	buttonClass?: string
}

const ActionButton: FunctionComponent < ButtonProps > = ({actionType, actionName, clickHandler, buttonClass = ''}) => {
		return (
			<div className={`mx-1 d-inline-flex`}>
				<button onClick={clickHandler} className={buttonClass ? `btn ${buttonClass}` : "btn btn-outline-success"}>
					{startCase(actionType)} {startCase(actionName)}
				</button>
			</div>
    )
	}

export default ActionButton;