import React, { Component } from "react";

type Props = {
  label: string;
  list: string[];
};

class EditableList extends Component<Props> {
  state = {
    list: this.props.list || []
  };

  addItem() {
    if (this.state.list.includes("")) return;
    this.state.list.push("");
    this.setState({ list: this.state.list });
  }

  removeItem(index: number) {
    this.state.list.splice(index, 1);
    this.setState({ list: this.state.list });
  }

  updateItem(event: React.FormEvent<HTMLInputElement>, index: number) {
    const list = this.state.list;
    list[index] = event.currentTarget.value;
    this.setState({ list: this.state.list });
  }

  render() {
    return (
      <div>
        {this.props.label}{" "}
        <button
          onClick={this.addItem.bind(this)}
          className="btn btn-sm btn-success ml-2"
        >
          +
        </button>
        <div className="mt-2 pl-3">
          {this.state.list.map((item, i) => (
            <div key={item || i} className="input-group mb-2">
              <input
                type="text"
                defaultValue={item}
                onBlur={e => this.updateItem(e, i)}
                className="form-control m-0"
              />
              <button
                onClick={this.removeItem.bind(this, i)}
                className="btn btn-sm btn-danger"
              >
                x
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default EditableList;
