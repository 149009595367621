import React, {
	FunctionComponent
} from 'react';
import { connect } from "react-redux";
import { AppState } from "../../store/configureStore";
import Payment from "../../types/Payment";
import Casino from "../../types/Casino";

type PaymentsProps = {
  casino: Casino
} & LinkStateProps

const PaymentsTemplate: FunctionComponent < PaymentsProps > = ({casino, payments}) => {
    const casinoPayments = casino.payments.map(id =>
      payments.find(x => id === x.id)
    );
		return (
			<ul className="mb-0">
          {casinoPayments.map(payment =>
            payment ? (
              <li key={payment.id}>
                {payment.name}
                <img
                  src={payment.image}
                  className="ml-2"
                  alt="img"
                  style={{ height: "1rem" }}
                />
              </li>
            ) : (
              ""
            )
          )}
        </ul>
    )
	}

interface LinkStateProps {
  payments: Payment[];
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  payments: state.payments,
});

export default connect(mapStateToProps)(PaymentsTemplate);