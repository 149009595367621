import React, {
	FunctionComponent
} from 'react';
import Casino from "../../types/Casino";
import Language from "../../types/Language";

type ItemProps = { 
  casino: Casino,
  language: Language,
  itemCount: number,
  itemClickHandler: (language: Language) => void,
  rankClickHandler?: (event: React.FormEvent<HTMLInputElement>, previousRank: number) => void,
  deleteClickHandler?: () => void,
} 

const CasinoItemLanguage: FunctionComponent < ItemProps > = ({
    casino,
    language,
    itemCount,
    itemClickHandler,
    rankClickHandler,
    deleteClickHandler
  }) => {
    const selectOptions = Array.from(Array(itemCount), (e,i)=>++i)

		return (
      <div className="position-relative">
        <div
          key={language.id}
          onClick={() => {
            itemClickHandler(language)
          }}
          className="d-flex flex-row casino card p-2 cursor-pointer"
        >
          <div className="d-flex flex-column justify-content-center image-container">
            <img src={casino.image} className="w-100" alt="" />
          </div>
          <div className="d-flex flex-column pl-2">
            <span>Rank: {language.rank}</span>
            <span>Name: {casino.name}</span>
            {language.alternateName !== undefined ? (
              <span>Alternate Name: {language.alternateName}</span>
            ) : (
              ""
            )}
            {/* TODO: Add check if links work */}
            <span>Go Link: {casino.goUrl}</span>
            {/* TODO: Add check if links work */}
            <span>Go To: {language.goTo}</span>
            <span>Rating: {casino.rating}</span>
            <span>Bonus Value: {language.bonusValue}</span>
            <span>Bonus Offer: {language.bonusOffer || 'n/a'}</span>
            <span>Signup Bonus: {language.signupBonus}</span>
            <span>
              Key Features:
              <ul className="mb-0">
                {language.keyFeatures && language.keyFeatures.length
                  ? language.keyFeatures.map((kf: string) => (
                      <li key={kf}>{kf}</li>
                    ))
                  : "No key feature"}
              </ul>
            </span>
            <span>
              Tags:
              <ul className="mb-0">
                {Array.isArray(casino.tags)
                  ? casino.tags.map(tag => (
                      <li key={tag}>{tag}</li>
                    ))
                  : "No tags."}
              </ul>
            </span>
          </div>
        </div>
        <div 
          className="position-absolute d-flex"
          style={
            { 
              top: "10px",
              right: "5px"
            }
          }>
          <div className={rankClickHandler ? "mx-1" : "d-none"}>
            <select 
              id="lang" 
              className="form-control py-0 px-2"
              style={
                {
                  height: "29px"
                }
              } 
              onChange={(e: any) => {
              if (rankClickHandler) rankClickHandler(e, language.rank)
            }} value={language.rank}>
              {
                // TODO: Fix ranking on tag
                selectOptions.map((val, index) => {
                  return (<option className={val === language.rank ? "text-muted" : ""} value={val}>{val}</option>)
                })
              }
            </select>
          </div>
          <div className={deleteClickHandler ? "mx-1" : "d-none"}>
            <button 
              className="btn btn-danger"
              onClick={deleteClickHandler}>
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }

export default CasinoItemLanguage;