import React, {
	FunctionComponent
} from 'react';
import { connect } from "react-redux";
import { AppState } from "../../store/configureStore";
import Casino from "../../types/Casino";
import Toplist from "../../types/Toplist";
import PaymentsTemplate from "./PaymentsTemplate"

type ItemProps = {
	data: Casino,
	clickHandler: () => void
} & LinkStateProps

const CasinoItem: FunctionComponent < ItemProps > = ({data, clickHandler, toplists}) => {
		return (
			<div
            key={data.id}
            onClick={clickHandler}
            className="d-flex flex-row casino card p-2 cursor-pointer"
          >
            <div className="d-flex flex-column justify-content-center image-container">
              <img src={data.image} className="w-100" alt="" />
            </div>
            <div className="d-flex flex-column pl-2">
              <span>Name: {data.name}</span>
              <span>Website: {data.website}</span>
              <span>Go Link: {data.goUrl}</span>
              <span>
                Payments:&nbsp;
                <PaymentsTemplate casino={data} />
              </span>
              <span>
                Tags:
                <ul className="mb-0">
                  {Array.isArray(data.tags)
                    ? data.tags.map(tag => <li key={tag}>{tag}</li>)
                  : "No tags."}
                </ul>
              </span>
            </div>
          </div>
    )
	}

interface LinkStateProps {
	toplists: Toplist[];
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
	toplists: state.toplists,
});

export default connect(mapStateToProps)(CasinoItem);