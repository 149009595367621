import React, {FunctionComponent} from 'react'

type FilterProps = {
  tags: string[],
  orderBy: string,
  filterHandler: any,
  tagHandler: any
}

const ItemFilter: FunctionComponent < FilterProps > = ({tags, orderBy, filterHandler, tagHandler}) => {
  return (
    <div>
      <h4>Simulate filters</h4>
      <div className="mt-2">
        <h5 className="mb-1">Order by:</h5>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="orderBy" value="asc"
            checked={orderBy==="asc" } onChange={filterHandler}></input>
          <label className="form-check-label">Ascending</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="orderBy" value="desc"
            checked={orderBy==="desc" } onChange={filterHandler}></input>
          <label className="form-check-label">Descending</label>
        </div>
      </div>
      <div className="mt-2">
        <h5 className="mb-1">Tags:</h5>
        {tags.map(tag => (
        <div key={tag} className="form-check">
          <input className="form-check-input" type="checkbox" value={tag} defaultChecked={true}
            onChange={tagHandler}></input>
          <label className="form-check-label">{tag}</label>
        </div>
        ))}
      </div>
    </div>
  )
}

export default ItemFilter;