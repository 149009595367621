import React, { Component } from "react";
import { connect } from "react-redux";
import Casino from "../types/Casino";
import { AppState } from "../store/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../types/actions";
import { Tabs, Tab } from "react-bootstrap";
import Language from "../types/Language";
import Modal from "react-responsive-modal";
import EditableList from "../utils/EditableList";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { addLanguage, setLanguage, deleteLanguage } from "../actions/languages";
import { addToplist, setToplist } from "../actions/toplists";
import Setting from "../types/Setting";
import { addSetting, setSetting } from "../actions/settings";
import { selectStyles } from "../config/const";
import Site from "../types/Site";
import Toplist from "../types/Toplist";
import SelectTypes from "../types/Select";
import { startCase } from "lodash";
import ActionButton from "./subcomponents/ActionButton";
import ItemFilter from "./subcomponents/ItemFilter";
import CasinoItemLanguage from "./subcomponents/CasinoItemLanguage";

type Props = PassedProps & LinkStateProps & LinkDispatchProps;
type tag = string;

class Toplists extends Component<Props> {
  state = {
    uncheckedTags: [] as tag[],
    sortBy: "rating",
    orderBy: "desc",
    activeLanguage: "",
    activeCountry: "Default",
    modalTitleType: "",
    modalTitle: "",
    modalExtraTitle: "",
    modalIsOpen: false,
    modalSelectedCasino: {} as SelectTypes,
    modalSelectedCountry: {} as SelectTypes,
    modalSelectedLanguage: {} as SelectTypes,
    modalSelectedToplist: undefined as any,
    modalErrors: [],
    displayedToplist: {} as SelectTypes
  };

  languageForm = React.createRef() as any;
  selectedLanguage = {} as Language;
  selectedToplist = {} as Toplist;

  componentWillReceiveProps(nextProps: any, prevState: any) {
    const { toplists } = nextProps;
    const { displayedToplist } = this.state;
    let selectToplistOptions;
    if (toplists.length && !displayedToplist.hasOwnProperty("value")) {
      selectToplistOptions = toplists.map(
        (toplist: { name: any; id: any }) => ({
          label: toplist.name,
          value: toplist.id
        })
      );
      this.selectedToplist = toplists[0];
      this.setState({ displayedToplist: selectToplistOptions[0] });
    }
  }

  componentWillMount() {
    const { toplists } = this.props;
    const { displayedToplist } = this.state;
    let selectToplistOptions;
    if (toplists.length && !displayedToplist.hasOwnProperty("value")) {
      selectToplistOptions = toplists.map(toplist => ({
        label: toplist.name,
        value: toplist.id
      }));
      this.selectedToplist = toplists[0];
      this.setState({ displayedToplist: selectToplistOptions[0] });
    }
  }

  // componentDidMount() {}

  getTags(): tag[] {
    const tags = new Set();
    if (Array.isArray(this.props.casinos))
      this.props.casinos.forEach(casino => {
        if (Array.isArray(casino.tags))
          casino.tags.forEach(tag => tags.add(tag));
      });
    return [...tags] as tag[];
  }

  filterCasinosByToplist(displayedToplist: any) {
    const activeToplist = displayedToplist.value || null;
    const uncheckedTags = this.getTags().filter(tag =>
      this.state.uncheckedTags.includes(tag)
    );
    const casinos = this.props.languages
      .filter(lang => {
        const casino = this.props.casinos.find(
          casino => casino.id === lang.casino
        );
        const hasTag = !!uncheckedTags.length && casino ? casino.tags.some(tag => !uncheckedTags.includes(tag)) : true
        return (
          lang.toplist === activeToplist &&
          casino !== undefined &&
          hasTag
        );
      })
      .sort((languageA: Language, languageB: Language): number => {
        if (languageA === undefined || languageB === undefined) return 0;

        return this.state.orderBy === "asc"
          ? languageB.rank - languageA.rank
          : languageA.rank - languageB.rank;
      });

    return casinos;
  }

  setFilter(event: React.FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let newState = {} as any;
    newState[name] = value;
    this.setState(newState);
  }

  setTag(event: React.FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const uncheckedTags = this.state.uncheckedTags;

    if (uncheckedTags.includes(value))
      uncheckedTags.splice(uncheckedTags.indexOf(value), 1);
    else uncheckedTags.push(value);

    this.setState({ uncheckedTags });
  }

  getCasinosSelect() {
    return this.props.casinos.map(casino => ({
      label: casino.name,
      value: casino.id
    }));
  }

  getAssignedToplistSelectDefault() {
    const currentToplist = this.selectedLanguage.toplist;
    const defaultValue = this.getToplists().find(
      toplist => toplist.value == currentToplist
    );

    return defaultValue;
  }

  getLanguageCodeSelectDefault() {
    const { languageCode } = this.selectedLanguage;
    const defaultValue = this.props.languageCodes.find(
      code => code.value == languageCode
    );

    return defaultValue;
  }

  getToplistOptions() {
    return this.props.toplists.map(toplist => ({
      label: toplist.name,
      value: toplist.id
    }));
  }

  getAvailableToplistsSelect() {
    const selectedLanguage = this.selectedLanguage;
    const duplicateItems = this.props.languages
      .filter(
        language =>
          language.casino === selectedLanguage.casino &&
          language.languageCode === selectedLanguage.languageCode
      )
      .map(language => language.toplist);

    return this.getToplists().filter(
      toplist =>
        ![...new Set(duplicateItems)].some(item => item == toplist.value)
    );
  }

  getToplistSelectDefault() {
    const toplists = this.getToplistOptions();
    return toplists.length ? toplists[0] : this.state.displayedToplist;
  }

  getToplists() {
    let toplists = this.getToplistOptions();
    return toplists;
  }

  onItemClick(language: Language) {
    this.selectedLanguage = JSON.parse(JSON.stringify(language));

    this.setState({
      modalTitleType: "Edit",
      modalTitle: "Casino",
      modalExtraTitle: "Translation",
      modalIsOpen: true,
      modalSelectedToplist: this.selectedLanguage.toplist,
      modalSelectedLanguage: { value: this.selectedLanguage.languageCode },
      modalSelectedCasino: { value: this.selectedLanguage.casino }
    });
  }

  onChangeRank(event: React.FormEvent<HTMLInputElement>, previousRank: number) {
    const activeToplist = this.state.displayedToplist.value || null;
    const target = event.target as HTMLInputElement;
    const currentValue = target.value;
    const { displayedToplist } = this.state
    const { languages } = this.props
    const currentLanguage = languages.find(language => language.languageCode === this.getLanguageDefault() && language.rank === previousRank && language.toplist === activeToplist)
    const targetLanguage = languages.find(language => language.languageCode === this.getLanguageDefault() && language.rank === parseInt(currentValue) && language.toplist === activeToplist)
    const postLanguage = (language: Language[]) => {
      return this.props.setLanguage(language)
        .then((response: any) => {
          this.onSelectDisplayedToplist.call(
            this,
            displayedToplist,
            language
          );
        })
        .catch((response: any) => {
          const { data } = response;

          if (!Object.keys(data).length) return;

          errors =
            typeof data === "string"
              ? ["Error: Integrity Error"]
              : Object.keys(data).map(key => {
                  return `${startCase(key)}: ${data[key]}`;
                });
        });
    };
    let errors
    const postItems = [currentLanguage]

    if (currentLanguage === undefined) return
    currentLanguage.rank = parseInt(currentValue)
    if(targetLanguage) {
      targetLanguage.rank = previousRank
      postLanguage([currentLanguage, targetLanguage])
      return
    }
  
    postLanguage([currentLanguage])
  }

  onEditToplist() {
    this.selectedToplist = this.props.toplists.find(
      toplist => toplist.id === this.state.displayedToplist.value
    ) as Toplist;
    this.setState({
      modalTitleType: "Edit",
      modalTitle: "Toplist",
      modalIsOpen: true,
      modalSelectedToplist: this.state.displayedToplist.value,
      modalSelectedCasino: {},
      modalSelectedLanguage: {}
    });
  }

  onEditAffiliateLink() {
    this.selectedToplist = this.props.toplists.find(
      toplist => toplist.id === this.state.displayedToplist.value
    ) as Toplist;
    this.setState({
      modalTitleType: "Edit",
      modalTitle: "Link",
      modalIsOpen: true,
      modalSelectedToplist: this.state.displayedToplist.value,
      modalSelectedCasino: {},
      modalSelectedLanguage: {}
    });
  }

  onRemoveLanguage(language: Language) {

    this.selectedLanguage = JSON.parse(JSON.stringify(language));
    this.setState({
      modalTitleType: "Delete",
      modalTitle:"Casino",
      modalIsOpen: true
    });
  }

  onSelectAvailableToplist(modalSelectedToplist: any) {
    this.setState({ modalSelectedToplist: modalSelectedToplist.value });
  }

  onSelectDisplayedToplist(
    displayedToplist: any,
    newData: any = { code: "", languageCode: "" }
  ) {
    const modalSelectedToplist = displayedToplist.value;
    const activeLanguage = !!newData.languageCode
      ? newData.languageCode
      : this.state.activeLanguage;
    this.selectedToplist = this.props.toplists.find(
      toplist => toplist.id === displayedToplist.value
    ) as Toplist;
    this.setState({
      displayedToplist,
      modalSelectedToplist,
      activeLanguage,
      modalIsOpen: false,
      modalErrors: []
    });
  }
  getLanguageDefault() {
    const { activeLanguage, displayedToplist } = this.state;
    const toplistItems = this.filterCasinosByToplist(displayedToplist);
    let languageCodes = [...new Set(toplistItems.map(item => item.languageCode))];

    languageCodes = languageCodes.sort();
    const newLanguage =
      !!activeLanguage && languageCodes.includes(activeLanguage)
        ? activeLanguage
        : languageCodes[0];

    return newLanguage;
  }

  getCasinoItemTpl() {
    const { displayedToplist } = this.state;
    const toplistItems = this.filterCasinosByToplist(displayedToplist);
    const languages = [...new Set(toplistItems.map(item => item.languageCode))];

    return (
      <Tabs
        id={`languages-tabs-${this.state.displayedToplist.value}`}
        activeKey={this.getLanguageDefault()}
        onSelect={(activeLanguage: any) => {
          this.setState({ activeLanguage });
        }}
      >
        {languages.length
          ? languages.map((language, index) => {
              const languageItems = toplistItems.filter(
                item => item.languageCode === language
              );
              const tabTitle = this.props.languageCodes.find(
                code => code.value === language
              ).label;
              return (
                <Tab
                  key={`${language}-${this.state.displayedToplist.value}-${index}`}
                  eventKey={language}
                  title={tabTitle}
                >
                  {languageItems.map(languageItem => {
                    const casino = this.props.casinos.find(
                      casino => casino.id === languageItem.casino
                    );
                    return casino !== undefined ? (
                      <CasinoItemLanguage 
                        casino={casino}
                        language={languageItem}
                        itemCount={languageItems.length}
                        itemClickHandler={this.onItemClick.bind(this)}
                        rankClickHandler={this.onChangeRank.bind(this)}
                        deleteClickHandler={this.onRemoveLanguage.bind(this, languageItem)}
                      />
                    ) : (
                      ""
                    );
                  })}
                </Tab>
              );
            })
          : ""}
      </Tabs>
    );
  }

  getAddToplistTpl() {
    return (
      <div>
        <div>
          Name * <input type="text" name="name" className="form-control" />
        </div>
        <div>
          Description{" "}
          <input type="text" name="description" className="form-control" />
        </div>
      </div>
    );
  }

  getAddCasinoTpl() {
    return (
      <div>
        <div className="">
          <span>Toplist</span>
          <Select
            // isMulti
            defaultValue={this.state.displayedToplist}
            noOptionsMessage={() => "No more options"}
            options={this.getToplists.call(this)}
            onChange={this.onSelectToplist.bind(this)}
            styles={selectStyles}
          ></Select>
        </div>
        <div className="">
          <span>Language / Language Code</span>
          {/*  */}
          <Select
            options={this.props.languageCodes}
            noOptionsMessage={() => "No more options"}
            onChange={this.onSelectLanguage.bind(this)}
            styles={selectStyles}
          ></Select>
        </div>
        Casino{" "}
        <Select
          options={this.getCasinosSelect.call(this)}
          noOptionsMessage={() => "No more options"}
          onChange={this.onSelectCasino.bind(this)}
          styles={selectStyles}
        ></Select>
        Go To{" "}
        <input
          type="text"
          name="goTo"
          defaultValue={this.selectedLanguage.goTo}
          className="form-control"
        />
      </div>
    );
  }

  onCloseModal() {
    this.setSelecttedLanguageDefault();
    this.setState({
      modalIsOpen: false,
      modalExtraTitle: "",
      modalSelectedCasino: { value: null },
      modalSelectedCountry: { value: null },
      modalSelectedLanguage: { value: null },
      modalSelectedToplist: this.state.displayedToplist.value,
      modalErrors: []
    });
  }

  setSelecttedLanguageDefault() {
    this.selectedLanguage = {
      languageCode: "",
      alternateName: "",
      bonusValue: 0,
      beforeBonusValue: "",
      afterBonusValue: "",
      bonusOffer: "",
      bonusOfferImage: "",
      signupBonus: "",
      keyFeatures: [],
      toplist: 0,
      casino: 0,
      goTo: "",
      rank: 0
    } as Language;
  }

  onAddCasino() {
    this.setSelecttedLanguageDefault();
    this.setState({
      modalTitleType: "Add",
      modalTitle: "Casino",
      modalIsOpen: true,
      modalSelectedToplist: this.state.displayedToplist.value,
      modalSelectedCasino: { value: null },
      modalSelectedCountry: { value: null },
      modalSelectedLanguage: { value: null }
    });
  }

  onAddToplist() {
    // this.setSelecttedLanguageDefault();
    this.setState({
      modalTitleType: "Add",
      modalTitle: "Toplist",
      modalIsOpen: true,
      modalSelectedToplist: {},
      modalSelectedCasino: { value: null },
      modalSelectedLanguage: { value: null }
    });
  }

  onSelectCasino(modalSelectedCasino: any) {
    this.setState({ modalSelectedCasino });
  }

  onSelectLanguage(modalSelectedLanguage: any) {
    this.setState({ modalSelectedLanguage });
  }

  onSelectToplist(modalSelectedToplist: any) {
    let selectedToplists = modalSelectedToplist || [];
    const toplists = selectedToplists.map((x: any) => x.value);

    this.setState({ modalSelectedToplist: toplists.length ? toplists : null });
  }

  onSelectDefaultCountry(modalSelectedCountry: any) {
    const propName = "defaultCountry";
    const currentValue = this.props.settings.find(
      setting => setting.prop === propName
    );
    const setting = {
      id: currentValue ? currentValue.id : undefined,
      prop: propName,
      value: modalSelectedCountry.value
    };
    let service;

    if (!currentValue) {
      service = this.props.addSetting;
    } else {
      service = this.props.setSetting;
    }

    service(setting);
  }
  async saveAffiliateLink() {
    const getInputValue = (name: string) => {
      return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    };
    let errors: string[] = [];
    const postToplist = (toplist: Toplist) => {
      return this.props.setToplist(toplist)
        .then((response: any) => {
          this.onSelectDisplayedToplist.call(
            this,
            this.state.displayedToplist,
            toplist
          );
        })
        .catch((response: any) => {
          errors = response
        });
    };

    const toplist = {
      name: this.selectedToplist.name,
      description: this.selectedToplist.description,
      defaultGoTo: getInputValue("defaultGoTo"),
      sites: []
    } as Toplist;

    if (this.selectedToplist.id) toplist.id = this.selectedToplist.id;

    postToplist(toplist).then((data: any) => {
      this.setState({ modalErrors: errors });
    });
  }

  async saveToplist() {
    const getInputValue = (name: string) => {
      return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    };
    let errors: string[] = [];
    const postToplist = (toplist: Toplist) => {
      return this.props[
        this.state.modalTitleType === "Add" ? "addToplist" : "setToplist"
      ](toplist)
        .then((response: any) => {
          this.onSelectDisplayedToplist.call(
            this,
            this.state.displayedToplist,
            toplist
          );
        })
        .catch((response: any) => {
          errors = response
        });
    };

    const toplist = {
      name: getInputValue("name"),
      description: getInputValue("description"),
      defaultGoTo: this.selectedToplist.defaultGoTo || "#",
      sites: []
    } as Toplist;

    if (this.selectedToplist.id) toplist.id = this.selectedToplist.id;

    postToplist(toplist).then((data: any) => {
      this.setState({ modalErrors: errors });
    });
  }

  async saveCasino() {
    const getInputValue = (name: string) => {
      return this.languageForm.current.querySelector(`[name="${name}"]`).value ;
    };
    const { modalSelectedToplist, modalTitleType, displayedToplist } = this.state
    const toplists = Array.isArray(modalSelectedToplist)
      ? modalSelectedToplist
      : [modalSelectedToplist];
    const { languages } = this.props;
    let errors: string[] = [];
    let language = {} as any

    const postLanguage = (language: Language & Language[]) => {
      return this.props[
        modalTitleType === "Delete" ? "deleteLanguage" : modalTitleType === "Add" ? "addLanguage" : "setLanguage"
      ](language)
        .then((response: any) => {
          this.onSelectDisplayedToplist.call(
            this,
            displayedToplist,
            language
          );
        })
        .catch((response: any) => {
          const { data } = response;
          
          if (!Object.keys(data).length) return;

          errors =
            typeof data === "string"
              ? ["Error: Contact developer."]
              : Object.keys(data).map(key => {
                  return `${startCase(key)}: ${data[key]}`;
                });
        });
    };
    if (modalTitleType !== "Delete") {
      language = {
        languageCode: this.state.modalSelectedLanguage.value,
        alternateName: getInputValue("name"),
        bonusValue: getInputValue("bonusValue"),
        beforeBonusValue: getInputValue("beforeBonusValue"),
        afterBonusValue: getInputValue("afterBonusValue"),
        bonusOffer: getInputValue("bonusOffer"),
        bonusOfferImage: getInputValue("bonusOfferImage"),
        signupBonus: getInputValue("signupBonus"),
        keyFeatures: this.selectedLanguage.keyFeatures || [],
        casino: this.state!.modalSelectedCasino!.value || null,
        goTo: getInputValue("goTo")
      } as Language
    }
      
    if (this.selectedLanguage.id) language.id = this.selectedLanguage.id;

    const resultsPromise = toplists.map(async (targetToplist: number) => {
      const targetToplistData = this.props.toplists.find(toplist => toplist.id === targetToplist) as Toplist
      const targetToplistItems = languages.filter(lang => lang.toplist === targetToplist && lang.languageCode === this.state.modalSelectedLanguage.value)
      let updatedLanguages

      language.toplist = targetToplist

      if (modalTitleType === "Add") {
        language.rank = targetToplistItems.length + 1
      } else if (modalTitleType === "Edit") {
        const isEqualToplistLanguage = language.languageCode === this.selectedLanguage.languageCode && targetToplist === this.selectedLanguage.toplist

        if (isEqualToplistLanguage) {
          let swapLanguage

          language.rank = Math.floor(getInputValue("rank"))
          swapLanguage = targetToplistItems.filter(item => item.id !== language.id).find(item => item.rank === language.rank)
          updatedLanguages = [language]
          if (swapLanguage) {
            swapLanguage.rank = this.selectedLanguage.rank
            updatedLanguages.push(swapLanguage)
          } else {
            language.rank = this.selectedLanguage.rank
          }
        } else {
          let isDuplicate = targetToplistItems.find(item => item.casino === language.casino && item.languageCode === language.languageCode)
          if (isDuplicate) {
            errors = [`DUPLICATE ENTRY FOR SITE(S): ${targetToplistData.name} - ${language.languageCode}`]
            return
          }
          let currentToplistItems = languages.filter(lang => lang.toplist === this.selectedLanguage.toplist && lang.languageCode === this.selectedLanguage.languageCode && this.selectedLanguage.id !== lang.id)
          language.rank = targetToplistItems.length + 1
          if (currentToplistItems) {
            currentToplistItems = currentToplistItems.sort((a,b) => a.rank > b.rank ? 1 : -1).map((item, i) => {
              item.rank = i + 1
              return item
            })
          }
          updatedLanguages = [language].concat(currentToplistItems)
        }
      }

      return postLanguage(updatedLanguages || language);
    });

    await Promise.all(resultsPromise).then(data => {
      const isEdit = this.state.modalTitleType === "Edit";
      const duplicateEntries = toplists
        .filter(toplist => !isEdit)
        .filter((toplist: any) => {
          const isDuplicate = languages
            .filter(lang => lang.toplist !== null && lang.toplist === toplist)
            .some(
              lang =>
                lang.casino === language.casino &&
                lang.languageCode === language.languageCode
            );

          return isDuplicate;
        });

      if (duplicateEntries.length) {

        errors.push(
          `Duplicate entry for Site(s): ${duplicateEntries
            .map(entry => {
              const toplist =
                this.props.toplists.find(site => site.id === entry) || ({} as any);
              return toplist.hasOwnProperty("name") ? toplist.name : [];
            })
            .join(", ")}`
        );
      }

      this.setState({ modalErrors: errors });
    });
  }

  async onModalSave() {
    // const getInputValue = (name: string) => {
    //   return this.languageForm.current.querySelector(`[name="${name}"]`).value;
    // };

    switch (this.state.modalTitle) {
      case "Toplist":
        this.saveToplist.call(this);
        break;
      case "Link":
        this.saveAffiliateLink.call(this);
        break;
      case "Casino":
        this.saveCasino.call(this);
        break;
    }
  }

  getActiveCountryTabLanguages() {
    return [
      ...new Set(
        this.props.languages.filter(language => {
          const { displayedToplist } = this.state;

          return language.toplist === displayedToplist.value;
        })
      )
    ].map(language => language.languageCode);
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    let updateApp = true;
    // if (state.activeLanguage !== nextState.activeLanguage) {
    //   updateApp = false;
    // }
    return updateApp;
  }

  render() {
    const { selectedToplist } = this;

    return (
      <div className="d-flex flex-column">
        <div className="text-center mb-3">
          <ActionButton 
            actionType="add" 
            actionName="toplist" 
            clickHandler={this.onAddToplist.bind(this)} />
        </div>
        <div className="">
          Select Toplist
          <div className="d-flex align-items-center">
            <Select 
              className="flex-grow-1 mr-1"
              value={{
                label: selectedToplist.name,
                value: selectedToplist.id
              }} noOptionsMessage={()=> "No more options"}
              options={this.getToplists.call(this)}
              onChange={this.onSelectDisplayedToplist.bind(this)}
              styles={selectStyles}
              ></Select>
            <div className={this.state.displayedToplist.value && this.state.displayedToplist.value !==1 ? "" : "d-none"
              }>
              <ActionButton actionType="edit" actionName="toplist" clickHandler={this.onEditToplist.bind(this)} />
            </div>
          </div>
        </div>
        <div className="text-center my-3">
          <ActionButton 
            actionType="add" 
            actionName="casino" 
            clickHandler={this.onAddCasino.bind(this)} />
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">{this.getCasinoItemTpl()}</div>
          <div className="ml-3" style={{ width: "200px" }}>
          <span className="small font-weight-bold">Default Affiliate Link:</span>
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
            <span className="small mb-1" style={{
              wordBreak: "break-all"
            }}>{this.selectedToplist.defaultGoTo === "#" ? "Empty" : this.selectedToplist.defaultGoTo}</span>
            <ActionButton buttonClass="btn-card small my-1" actionType="edit" actionName="link" clickHandler={this.onEditAffiliateLink.bind(this)} />
          </div>
          <ItemFilter 
            tags={this.getTags()} 
            orderBy={this.state.orderBy} 
            filterHandler={this.setFilter.bind(this)} 
            tagHandler={this.setTag.bind(this)}/>
          </div>
        </div>
        <Modal
          open={this.state.modalIsOpen}
          onClose={this.onCloseModal.bind(this)}
          classNames={{
            overlay: "modal-overlay",
            modal: "modal-body"
          }}
        >
          <h3 className="mb-3">
            {this.state.modalTitleType} {this.state.modalTitle}{" "}
            {this.state.modalExtraTitle}
          </h3>
          {this.state.modalIsOpen ? (
            <div ref={this.languageForm}>
              <ul className="modal-errors list-unstyled">
                {this.state.modalErrors.map(error => {
                  return <li className="text-error text-uppercase">{error}</li>;
                })}
              </ul>
              {
                this.state.modalTitleType === "Delete" ? (
                  <div>
                    <p>
                      Are you sure you want to{" "}
                      <span className="text-danger">delete</span> this item?
                    </p>
                  </div>
                ) : 
                  this.state.modalTitleType === "Add" ? (
                    this.state.modalTitle === "Toplist" ? (
                      this.getAddToplistTpl()
                    ) : (
                      this.getAddCasinoTpl()
                    )
                ) : this.state.modalTitle === "Toplist" ? 
                (
                  <div>
                    Name *{" "}
                    <input
                      type="text"
                      name="name"
                      defaultValue={this.selectedToplist.name}
                      className="form-control"
                    />
                    Description{" "}
                    <input
                      type="text"
                      name="description"
                      defaultValue={this.selectedToplist.description}
                      className="form-control"
                    />
                  </div>
                ) : this.state.modalTitle === "Link" ? 
                (
                  <div>
                    Default Affiliate Link {" "}
                    <input
                      type="text"
                      name="defaultGoTo"
                      defaultValue={this.selectedToplist.defaultGoTo}
                      className="form-control"
                    />
                  </div>
                ) :
              (
                <div className="mb-2">
                  <div className="mb-2">
                    {/* TODO: Show country */}
                    {/* <div className="mb-2">
                    Country:{" "}
                    <code>{this.selectedLanguage.code || "Default"}</code>
                  </div> */}
                    Casino:{" "}
                    <code>
                      {
                        this.props.casinos.find(
                          c => c.id === this.selectedLanguage.casino
                        )!.name
                      }
                    </code>
                  </div>
                  Rank{" "}
                  <input
                    type="number"
                    name="rank"
                    defaultValue={
                      this.selectedLanguage.rank
                        ? this.selectedLanguage.rank.toString()
                        : ""
                    }
                    min="1"
                    max={this.props.languages.filter(lang => lang.toplist === this.selectedToplist.id && lang.languageCode === this.getLanguageDefault()).length + 1}
                    className="form-control"
                  />
                  Assigned to{" "}
                  <Select
                    defaultValue={this.getAssignedToplistSelectDefault.call(
                      this
                    )}
                    noOptionsMessage={() => "No more options"}
                    options={this.getAvailableToplistsSelect.call(this)}
                    onChange={this.onSelectAvailableToplist.bind(this)}
                    styles={selectStyles}
                  ></Select>
                  <div className="">
                    <span>Language / Language Code</span>
                    <Select
                      defaultValue={this.getLanguageCodeSelectDefault.call(
                        this
                      )}
                      noOptionsMessage={() => "No more options"}
                      options={this.props.languageCodes}
                      onChange={this.onSelectLanguage.bind(this)}
                      styles={selectStyles}
                    ></Select>
                  </div>
                  Go To{" "}
                  <input
                    type="text"
                    name="goTo"
                    defaultValue={this.selectedLanguage.goTo}
                    className="form-control"
                  />
                </div>
              )}
              {["Toplist", "Link"].includes(this.state.modalTitle) || this.state.modalTitleType === "Delete" ? (
                ""
              ) : (
                <div>
                  Alternate Name{" "}
                  <input
                    type="text"
                    name="name"
                    defaultValue={this.selectedLanguage.alternateName}
                    className="form-control"
                  />
                  <div className="my-4">
                    Bonus Value{" "}
                    <input
                      type="number"
                      name="bonusValue"
                      defaultValue={
                        this.selectedLanguage.bonusValue
                        ? this.selectedLanguage.bonusValue.toString()
                        : ""
                      }
                      className="form-control"
                    />
                    <div className="ml-3">
                      <div className="d-flex align-items-center my-1"> 
                        <span className="small mr-2">Before</span> 
                        <input
                          type="text"
                          name="beforeBonusValue"
                          placeholder="Insert text before bonus value"
                          defaultValue={this.selectedLanguage.beforeBonusValue || ""}
                          className="form-control my-0 small"
                          />
                      </div>
                      <div className="d-flex align-items-center my-1"> 
                        <span className="small mr-3">After</span> 
                        <input
                          type="text"
                          name="afterBonusValue"
                          placeholder="Insert text after bonus value"
                          defaultValue={this.selectedLanguage.afterBonusValue || ""}
                          className="form-control my-0 small"
                          />
                      </div>
                    </div>
                  </div>
                  Bonus Offer{" "}
                  <input
                    type="text"
                    name="bonusOffer"
                    defaultValue={this.selectedLanguage.bonusOffer || ""}
                    className="form-control"
                  />
                  Bonus Offer Image{" "}
                  <input
                    type="text"
                    name="bonusOfferImage"
                    defaultValue={this.selectedLanguage.bonusOfferImage || ""}
                    className="form-control"
                  />
                  Signup Bonus{" "}
                  <input
                    type="text"
                    name="signupBonus"
                    defaultValue={this.selectedLanguage.signupBonus}
                    className="form-control"
                  />
                  <EditableList
                    label={"Key Features"}
                    list={this.selectedLanguage.keyFeatures}
                  ></EditableList>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="mt-5 text-right">
            <button
              className="btn btn-info mr-2"
              onClick={this.onCloseModal.bind(this)}
            >
              Cancel
            </button>
            <button
              className={"btn ".concat(this.state.modalTitleType === "Delete" ? "btn-danger" : "btn-primary")}
              onClick={this.onModalSave.bind(this)}
            >
              {this.state.modalTitleType === "Delete" ? "Confirm" : "Save"}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

interface PassedProps {
  languageCodes: any[];
}

interface LinkStateProps {
  casinos: Casino[];
  languages: Language[];
  settings: Setting[];
  sites: Site[];
  toplists: Toplist[];
}

interface LinkDispatchProps {
  addLanguage: (newLanguage: Language) => any;
  setLanguage: (updatedLanguage: Language[]) => any;
  deleteLanguage: (updatedLanguage: Language) => any;
  addToplist: (newToplist: Toplist) => any;
  setToplist: (updatedToplist: Toplist) => any;
  addSetting: (newSetting: Setting) => any;
  setSetting: (updatedSetting: Setting) => any;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  casinos: state.casinos || [],
  languages: state.languages || [],
  settings: state.settings,
  sites: state.sites,
  toplists: state.toplists
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AppActions>
): LinkDispatchProps => ({
  addLanguage: bindActionCreators(addLanguage, dispatch),
  setLanguage: bindActionCreators(setLanguage, dispatch),
  deleteLanguage: bindActionCreators(deleteLanguage, dispatch),
  addToplist: bindActionCreators(addToplist, dispatch),
  setToplist: bindActionCreators(setToplist, dispatch),
  addSetting: bindActionCreators(addSetting, dispatch),
  setSetting: bindActionCreators(setSetting, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Toplists);
