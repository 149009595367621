import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/configureStore";
import { AppActions, SET_SITES } from "../types/actions";
import Site from "../types/Site";
import { baseUrl } from "../config/const";

export const setSites = (sites: Site[]): AppActions => ({
  type: SET_SITES,
  sites
});

export const addSite = (
  newSite: Site
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>(resolve => {
      axios.post(`${baseUrl}/sites/`, newSite).then(res => {
        const site = res.data;
        const sites = [...getState().sites, site];

        dispatch(setSites(sites));
        resolve();
      });
    });
  };
};

export const setSite = (
  updatedSite: Site
): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (
    dispatch: ThunkDispatch<{}, {}, AppActions>,
    getState: () => AppState
  ): Promise<void> => {
    return new Promise<void>(resolve => {
      axios
        .put(`${baseUrl}/sites/${updatedSite.id}/`, updatedSite)
        .then(res => {
          const site = res.data;
          const sites = getState().sites.map((c: Site) => {
            return c.id === site.id ? site : c;
          });

          dispatch(setSites(sites));
          resolve();
        });
    });
  };
};

export const getSites = (): ThunkAction<Promise<void>, AppState, {}, any> => {
  return (dispatch: ThunkDispatch<{}, {}, AppActions>): Promise<void> => {
    return new Promise<void>(resolve => {
      axios.get(`${baseUrl}/sites/`).then(res => {
        dispatch(setSites(res.data));
        resolve();
      });
    });
  };
};
