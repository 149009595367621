import { PaymentActionTypes } from '../types/actions';
import Payment from '../types/Payment';

const defaultPaymentsState: Payment[] = [];

export function paymentsReducer (state = defaultPaymentsState, action: PaymentActionTypes) {
  switch (action.type) {
    case 'SET_PAYMENTS':
      return action.payments;
    default:
      return state;
  }
};